import moment from 'moment';
export const getDifferenceinHours = (pickupDateTime, returnDateTime) => {
    const date1 = new Date(pickupDateTime);
    const date2 = new Date(returnDateTime);
    return Math.abs(date2 - date1) / 36e5;
};
export const getTimeStops = (start, end) => {
    let endTime = moment(end, 'hh:mm A');
    let startTime = moment(start, 'hh:mm A');
    // if (endTime.isBefore(startTime)) {
    //     endTime.add(1, 'day');
    // }
    let timeStops = [];
    let count = 0;
    while (startTime < endTime) {
        if (count > 0) {
            startTime.add(30, 'minutes');
        }
        // const currentTime = moment().add(30, 'minutes');
        let timeAfterOffset = '';
        // if (startTime > currentTime) {
        timeStops.push(new moment(startTime).format('hh:mm A'));
        count++;
        // }
    }
    return timeStops;
};
export const makeTimeInterval = (startTime = '') => {
    const start = startTime || moment();
    const remainder = 30 - (start.minute() % 30);
    const dateTime = moment(start).add(remainder, 'minutes');
    return dateTime;
};
export const tommorrowDate = () => {
    let tommorrowDate = moment().add(1, 'days');
    tommorrowDate = moment(tommorrowDate).format('LL');
    return tommorrowDate;
};
export const convertDateToMomentDateTimeNonUTC = (date, selTime) => {
    let dateMoment = date ? moment(date) : moment();
    let pickupTime = '';
    let currTime = moment();
    if (selTime) {
        pickupTime = moment(selTime, 'h:mm A');
    }
    const dateAndTime = dateMoment.set({
        hour: pickupTime ? pickupTime.get('hour') : currTime.get('hour'),
        minute: pickupTime ? pickupTime.get('minute') : currTime.get('minute'),
        second: 0,
        millisecond: 0
    });
    let dateTimeFormatted = moment(dateAndTime?.toDate()).format('YYYY-MM-DDTHH:mm:ss[Z]');
    return dateTimeFormatted;
};
export const addTPlusNDays = selectedStartDate => {
    const dow = moment(selectedStartDate).day();
    let days = 4;
    if (dow === 3) {
        days = 5;
    }
    if (dow === 2) {
        days = 6;
    }
    return days;
};
export const MakeArrayOfObjects = arr => {
    let arr2 = [];
    arr?.forEach(ele => {
        arr2.push({
            value: `${ele ? ele : ''}`,
            label: `${ele ? ele : ''}`
        });
    });
    return arr2;
};
export const makeArrayFromObjects = Obj => {
    let arr2 = [];
    for (let i in Obj) {
        arr2.push({
            identifier: i,
            value: Obj[i].value,
            number: Obj[i].number
        });
    }
    return arr2;
};
export const handleAnonymousIdFormatting = (guid = '') => {
    if (guid === '' || !guid) {
        return undefined;
    }
    let rxGetGuidGroups = /(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/;
    let formattedGUID = guid.replace(rxGetGuidGroups, '$1-$2-$3-$4-$5').toUpperCase();
    return formattedGUID;
};
export const setPrefilledAddressForCreateProject = (streetAddress, selectedCity) => {
    let addrLine1 = streetAddress?.split(',')[0]?.trim();
    if (addrLine1 == selectedCity) {
        addrLine1 = '';
    }
    return addrLine1;
};
/**
 * get total quantity, total price of rentals and addons in consumables
 *
 * @param {*}  selectedObj accept the selected object from context
 * @returns {}
 */
export const getTotalFromConsumables = selectedObj => {
    let totalRentalPrice = 0;
    let totalRentalQty = 0;
    let totalAddonPrice = 0;
    let totalAddonQty = 0;
    Object.keys(selectedObj).forEach(item => {
        let selectedArray = Object.keys(selectedObj[item]);
        if (selectedArray.includes('rentals')) {
            let rentalArray = Object.values(selectedObj[item]['rentals']);
            rentalArray.forEach(itemRental => {
                totalRentalQty += itemRental.qty;
                totalRentalPrice += itemRental.qty * itemRental.price;
            });
        }
        if (selectedArray.includes('addons')) {
            let addonArray = Object.values(selectedObj[item]['addons']);
            addonArray.forEach(itemAddon => {
                totalAddonQty += itemAddon.qty;
                totalAddonPrice += itemAddon.qty * itemAddon.price;
            });
        }
    });
    return { totalRentalPrice, totalRentalQty, totalAddonQty, totalAddonPrice };
};