import React, { memo } from 'react';
import { bool, func, instanceOf, object, oneOfType, string } from 'prop-types';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../contexts/cart';
import isObjectEmpty from '../../../aem-core-components/utils/isObjectEmpty';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import useMedia from '../../../hooks/useMedia';
import { useCapUtils } from '../hooks/useCapUtils';
import { createFullAddress } from '../utils/capHelper';
import Button from '../../global/atoms/button';
import LocationPin from '../../../resources/images/location_green_20x20.svg';
import EditIcon from '../../../resources/images/edit-small-green.svg';
import CalendarIcon from '../../../resources/images/calendar_green_20x20.svg';
import CarIcon from '../../../resources/images/car_green_20x20.svg';
import ChevronRight from '../../../resources/images/chevron-right.svg';
import './readOnlyPersistantHeader.scss';
import AlertCircle from '../../../resources/images/alert-circle-black.svg';
import { capDataLocator } from '../dataLocator';
import { AUTHORITY_TYPE } from '../../global/constants';
import { FULFILLMENT_TYPE } from '../constants';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import './readOnlyPersistantHeader.scss';

const ReadOnlyPersistantHeader = ({
    toggleViewMode,
    selectedStoreDetails,
    handleDateOverlay,
    handleLocationOverlay,
    startDate,
    endDate,
    addressText,
    jobsiteDetails,
    fulfillmentValue,
    isJobsiteSelected,
    isEditView,
    formInteractionStartedEventAnalytics,
    sendDeliveryInteractedEvent,
    pickupstoreContainerRef,
    locationContainerRef,
    isStoreLocationFetching
}) => {
    const capIntl = useIntl();
    const userType = useCheckUser();
    const { getSelectedStoreLabel, isStoreDetailsUpdated } = useCapUtils();
    const mediaType = useMedia();
    const deliveryMethod = EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_DELIVERY_METHOD;
    const isPickup = fulfillmentValue === FULFILLMENT_TYPE.PICKUP;
    const [{ userAccount }] = useCartState();
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const { getCapLocationLabel } = useCapUtils();
    const chooseAccountAndJobsiteLabel = getCapLocationLabel(capIntl.formatMessage({ id: 'cap:set-rental-location' }));

    const isLocationEmpty = () => {
        if (addressText || jobsiteDetails?.projectName) {
            return false;
        } else {
            return true;
        }
    };

    const getLocation = () => {
        if (userType === USER_TYPE.CREDIT && isJobsiteSelected && jobsiteDetails?.projectName) {
            return `${jobsiteDetails?.projectName} - ${createFullAddress(jobsiteDetails)}`;
        } else {
            return addressText;
        }
    };

    const getBarTitle = () => {
        if (isLocationEmpty() && (!startDate || !endDate)) {
            return capIntl.formatMessage({ id: 'cap:enter-rental-details' });
        } else if (isLocationEmpty()) {
            return capIntl.formatMessage({ id: 'cap:enter-rental-location' });
        } else {
            return capIntl.formatMessage({ id: 'cap:enter-rental-dates' });
        }
    };

    const getSelectedStoreDetails = () => {
        const storeLabel = getSelectedStoreLabel(selectedStoreDetails);
        if (isObjectEmpty(selectedStoreDetails)) {
            return (
                <>
                    {fulfillmentValue}:{' '}
                    <span ref={pickupstoreContainerRef} className="tooltip-pickup-store">
                        {storeLabel}
                    </span>
                    <AlertCircle className="alert-circle-icon" />
                </>
            );
        } else {
            return (
                <>
                    <span className="pickup-hover">{capIntl.formatMessage({ id: 'cap:picking-up' })}</span>
                    <span className="neutral-600">
                        <small>{capIntl.formatMessage({ id: 'cap:from' })}</small>
                    </span>
                    <span ref={pickupstoreContainerRef} className="tooltip-pickup-store pickup-hover">
                        {storeLabel}
                    </span>
                </>
            );
        }
    };

    const fulFillmentTypeHandler = () => {
        toggleViewMode();
        formInteractionStartedEventAnalytics(deliveryMethod);
        sendDeliveryInteractedEvent(deliveryMethod, fulfillmentValue);
    };

    const renderAddress = () => {
        if (isLocationEmpty()) {
            return (
                <div ref={locationContainerRef} className="tooltip-address">
                    <Button
                        className="viewMode-button"
                        data-testid={capDataLocator.set_rental_location_testid}
                        buttonAriaLabel={chooseAccountAndJobsiteLabel}>
                        {chooseAccountAndJobsiteLabel}
                    </Button>
                </div>
            );
        } else if (userType === USER_TYPE.CREDIT && isJobsiteSelected && jobsiteDetails?.projectName) {
            return (
                <>
                    <small className="neutral-600">{capIntl.formatMessage({ id: 'cap:renting-to' })}</small>
                    <address
                        tabIndex="0"
                        aria-label={`${capIntl.formatMessage({ id: 'cap:renting-to' })}: ${
                            jobsiteDetails?.projectName || ''
                        } ${createFullAddress(jobsiteDetails) || ''}`}
                        ref={locationContainerRef}
                        className="tooltip-address jobsite-address neutral-600 text-ellipsis">
                        <span className="neutral-900 text-ellipsis">{jobsiteDetails?.projectName}</span>{' '}
                        {createFullAddress(jobsiteDetails)}
                    </address>
                </>
            );
        } else {
            return (
                <>
                    <small className="neutral-600">{capIntl.formatMessage({ id: 'cap:renting-to' })}</small>
                    <span
                        ref={locationContainerRef}
                        className="tooltip-address neutral-900 text-ellipsis address-text-hover ">
                        {addressText}
                    </span>
                </>
            );
        }
    };

    const renderLocation = () => (
        <div
            className="content-group text-ellipsis content-group-hover"
            onClick={handleLocationOverlay}
            tabIndex={isLocationEmpty() ? -1 : 0}>
            <LocationPin aria-hidden={'true'} />
            {renderAddress()}
        </div>
    );

    const renderMomentConvertedDate = () => {
        return `${moment(startDate)?.format('M/D/YY')} — ${moment(endDate)?.format('M/D/YY')}`;
    };

    const renderDatePicker = () => (
        <div
            className="content-group content-group-hover"
            onClick={handleDateOverlay}
            tabIndex={startDate && endDate ? 0 : -1}>
            <CalendarIcon aria-hidden={'true'} />
            {startDate && endDate ? (
                <>
                    <span className="visually-hidden">{capIntl.formatMessage({ id: 'cap:rental-dates' })}</span>
                    <span className="date-picker-hover">
                        {moment(startDate).format('M/D/YY')} — {moment(endDate).format('M/D/YY')}
                    </span>
                </>
            ) : (
                <Button
                    className="viewMode-button"
                    data-testid={capDataLocator.set_rental_date_testid}
                    onClick={handleDateOverlay}
                    buttonAriaLabel={capIntl.formatMessage({ id: 'cap:set-rental-dates' })}>
                    {capIntl.formatMessage({ id: 'cap:set-rental-dates' })}
                </Button>
            )}
        </div>
    );

    const renderFulfillmentType = () => {
        return (
            <div className="content-group content-group-hover" onClick={fulFillmentTypeHandler} tabIndex={0}>
                <CarIcon aria-hidden={'true'} />
                {!isLocationEmpty() && isPickup && isStoreDetailsUpdated(isStoreLocationFetching) ? (
                    getSelectedStoreDetails()
                ) : (
                    <>
                        <span className="visually-hidden">{capIntl.formatMessage({ id: 'cap:fulfillment-type' })}</span>
                        <small className="neutral-600 pickup-hover">{fulfillmentValue}</small>
                    </>
                )}
            </div>
        );
    };

    const renderEditBtn = () => (
        <div className="edit-group" onClick={toggleViewMode}>
            <EditIcon aria-hidden={'true'} />
            <Button
                className="edit-button"
                data-testid={capDataLocator.edit_cta_testid}
                buttonAriaLabel={capIntl.formatMessage({ id: 'cap:edit-rental-details' })}>
                {capIntl.formatMessage({ id: 'cap:edit' })}
            </Button>
        </div>
    );

    const renderReadOnlyDesktopView = () => (
        <div
            className={`readOnlyPersistantCapHeader ${
                isEditView ? 'readonly-persistent-inactive' : 'readonly-persistent-active'
            }`}>
            {isEditView && (
                <div className="readOnlyPersistantCapHeader-header">
                    <div className="cap-details-group">
                        {renderLocation()}
                        {renderDatePicker()}
                        {renderFulfillmentType()}
                    </div>
                    {renderEditBtn()}
                </div>
            )}
        </div>
    );

    const renderPartialRentalBar = () => {
        const title = getBarTitle();
        return (
            <Button
                className="rental-bar partial-filled-bar"
                buttonAriaLabel={title}
                onClick={toggleViewMode}
                data-testid={capDataLocator.rental_bar_testid}>
                <CarIcon className="rental-bar__car-icon" />
                <p className="partial-filled-title">{title}</p>
                <ChevronRight />
            </Button>
        );
    };

    const renderFilledRentalBar = () => (
        <div className="rental-bar">
            <div className="rental-bar__details">
                <address className="location-store-details text-ellipsis">
                    {isPickup ? getSelectedStoreLabel(selectedStoreDetails) : getLocation()}
                </address>
                <div className="content-group">
                    <small>{fulfillmentValue}</small>
                    <div className="content-seperator">•</div>
                    <small>
                        {moment(startDate).format('M/D')}—{moment(endDate).format('M/D')}
                    </small>
                </div>
            </div>
            {renderEditBtn()}
        </div>
    );

    const renderRentalDetailsBar = () => {
        if (isLocationEmpty() || !startDate || !endDate) {
            return renderPartialRentalBar();
        } else {
            return renderFilledRentalBar();
        }
    };

    return mediaType === MEDIA_TYPE.DESKTOP ? renderReadOnlyDesktopView() : renderRentalDetailsBar();
};

ReadOnlyPersistantHeader.defaultProps = {
    toggleViewMode: () => {},
    selectedStoreDetails: {},
    isJobsiteSelected: false,
    jobsiteDetails: {},
    handleDateOverlay: () => {},
    handleLocationOverlay: () => {},
    startDate: '',
    endDate: '',
    addressText: '',
    fulfillmentValue: '',
    formInteractionStartedEventAnalytics: () => {},
    sendDeliveryInteractedEvent: () => {},
    isStoreLocationFetching: false
};

ReadOnlyPersistantHeader.propTypes = {
    toggleViewMode: func,
    selectedStoreDetails: object,
    isJobsiteSelected: bool,
    jobsiteDetails: object,
    handleDateOverlay: func,
    handleLocationOverlay: func,
    startDate: oneOfType([instanceOf(Date), string]),
    endDate: oneOfType([instanceOf(Date), string]),
    addressText: string,
    fulfillmentValue: string,
    formInteractionStartedEventAnalytics: func,
    sendDeliveryInteractedEvent: func,
    isStoreLocationFetching: bool
};

export default memo(ReadOnlyPersistantHeader);
