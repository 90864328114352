import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { uuid4 } from '@sentry/utils';
import MUTATION_LOG_ERRORS_TO_SPLUNK from '../../../aem-core-components/queries/mutation_log_errors_to_splunk';
import { cookieValue } from '../../../aem-core-components/utils/cookieUtils';
import { CANCELLED_REQUEST_ERRORS, ERROR_MSG } from '../../../constants/errorMappingCodes';
import config from '../../App/config';
import { USER_CANCELLED_REQUEST_MSG } from '../../cap/constants';
import { useCheckAuthorityType } from '../../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../constants';

const isDevelopment = process.env.NODE_ENV === 'development';
const authorityType = useCheckAuthorityType();
let splunkConfigs = {};

export const isValidString = inputStr => {
    let str = String(inputStr)?.toLowerCase();
    if (
        str === 'null' ||
        str === 'undefined' ||
        str === '' ||
        str === 'false' ||
        str === 'no' ||
        str === '0' ||
        str === 'nan'
    ) {
        return false;
    } else if (str === 'true') {
        return true;
    }
    return str;
};

var sessionId = isValidString(cookieValue('guestsessionid')) || uuid4();
let userId = cookieValue('sbuid') || cookieValue('guest_user_id');
userId = isValidString(userId) ? userId : uuid4();

const genericCorrelation = `${userId}${sessionId?.substring(0, 16)}`;

export const getEnv = () => {
    var host = window.location.hostname;
    if (host.includes('localhost')) {
        return 'localhost';
    } else if (host.includes('dev-www')) {
        return 'development';
    } else if (host.includes('qa-www')) {
        return 'qa';
    } else if (host.includes('uat-www')) {
        return 'uat';
    } else if (host.includes('stage-www')) {
        return 'stage';
    } else if (host === 'www.sunbeltrentals.com') {
        return 'production';
    }
};
export const initSentry = async () => {
    Sentry.init({
        dsn: 'https://9bdf146ced3a46dda9e5df31a9668804@o102828.ingest.sentry.io/5981171',
        integrations: [new Integrations.BrowserTracing()],
        release: '2.2.0',
        tracesSampleRate: 0.2,
        debug: false,
        environment: getEnv(),
        attachStacktrace: true,
        maxValueLength: 1500,
        beforeBreadcrumb: breadcrumb => {
            if (breadcrumb.category === 'console') {
                return null;
            }
            return breadcrumb;
        },
        ignoreErrors: CANCELLED_REQUEST_ERRORS
    });
};
const parseHttpHeaders = () => {
    var req = new XMLHttpRequest();
    req.open('GET', document.location, false);
    req.send(null);
    let allhttpHeaders = req.getAllResponseHeaders();
    let headers = allhttpHeaders
        .split('\n')
        .map(x => x.split(/: */, 2))
        .filter(x => x[0])
        .reduce((ac, x) => {
            ac[x[0]] = x[1];
            return ac;
        }, {});
    return headers['cf-ray'] ? headers['cf-ray'] : undefined;
};

const logErrorsSplunk = errorMsg => {
    const { graphqlEndpoint, headers = '{}' } = JSON.parse(
        document.querySelector('meta[name="store-config"]')?.content ||
            document.querySelector('div[name="store-config"]')?.getAttribute('content') ||
            '{}'
    );
    const options = {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: MUTATION_LOG_ERRORS_TO_SPLUNK,
            variables: {
                message: errorMsg
            },
            fetchPolicy: 'network-only'
        })
    };

    const logErrorGraphQL = async () => {
        try {
            const result = await fetch(`${window.location.origin}${graphqlEndpoint}`, options);
            await result.json();
        } catch (err) {
            console.log(err);
        }
    };

    logErrorGraphQL();
};

export const setSentryScope = (type = 'guest', cartId = '') => {
    Sentry.configureScope(function (scope) {
        scope.setTag('user_mode', type);
        scope.setTag('cart_id', cartId || cookieValue('cif.cart'));
        // scope.setTag('cf_ray_id', parseHttpHeaders());
        if (authorityType === AUTHORITY_TYPE.P2P) {
            scope.setTag('p2puser', true);
        }
        scope.setTag('current_page', window.location.pathname);
        scope.setTag('generic_correlation_id', genericCorrelation);
    });
};

export const setSentryScopeforSettings = settingCookie => {
    Sentry.configureScope(function (scope) {
        scope.setTag('settings_cookie', settingCookie);
    });
};

export const setSentryScopeforCorrelationID = (correlationId = '') => {
    Sentry.configureScope(function (scope) {
        scope.setTag('x-correlation-Id', correlationId);
    });
};

export const logSentryUserId = user => {
    Sentry.setUser({
        id: user?.oktaUserId || user?.guest_id,
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        sessionId: user?.magento_session_id
    });
};

export const clearSentryScope = () => {
    Sentry.configureScope(scope => {
        scope.clear();
    });
};

export const avoidWirecross = errorMessage => {
    if (errorMessage && JSON.stringify(errorMessage).includes(ERROR_MSG.CART_EXPIRED)) {
        return true;
    }
    return false;
};

export const logError = (error, isWireCross = false, method, ...payload) => {
    try {
        const payloadArray = [...payload];
        if (!error) return;
        const { headers = '' } = error?.response?.config || {};
        const correlationId = headers?.['x-correlation-Id'];
        if (correlationId) {
            setSentryScopeforCorrelationID(correlationId);
        }
        if (error?.message !== USER_CANCELLED_REQUEST_MSG) {
            Sentry.captureException(
                JSON.stringify(error) + `||${isWireCross}` + `||${method}||` + JSON.stringify(payloadArray)?.toString()
            );
            logErrorsSplunk(
                JSON.stringify(error) + `||${isWireCross}` + `||${method}||` + JSON.stringify(payloadArray)?.toString()
            );
        }

        setSentryScopeforCorrelationID();
        if (isWireCross && !avoidWirecross(error)) {
            // errorMsg = `Wires got crossed - ${errorMsg}, Request-Id: ${error?.config?.headers['Request-Id']}, URL: ${error?.config?.url}`;
            if (!isDevelopment) {
                if (window.location.pathname !== config?.pagePaths?.wiresCrossedPage) {
                    // to disable wirescrossed in live site for debugging purpose
                    const params = new URLSearchParams(window.location.search);
                    const redirect = params.get('redirect');
                    if (!redirect) {
                        window.location.href = config?.pagePaths?.wiresCrossedPage;
                    }
                }
            }
        }
    } catch (exp) {
        Sentry.captureException('Exception occured in logError');
    }
};

export const logWarning = warning => {
    if (!isDevelopment) {
        Sentry.captureMessage(warning);
    }
};

export const logSuccess = message => {
    if (!isDevelopment) {
        Sentry.captureMessage(message);
    }
};

export const generateCorrelationId = () => {
    return genericCorrelation ? `${genericCorrelation}${uuid4()?.substring(0, 8)}` : sessionId;
};

window.logger = {generateCorrelationId, logSuccess, logWarning, logError, clearSentryScope, logSentryUserId, setSentryScopeforSettings, setSentryScope, initSentry, getEnv};