import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { bool, func, number, string } from 'prop-types';
import { useIntl } from 'react-intl';
import StarSolidIcon from '../../../../../main/resources/images/star-solid.svg';
import StarIcon from '../../../../../main/resources/images/star.svg';
import { SET_IS_FAVORITES_LOADING, SET_JOBSITE_FAVORITE_LIST_DATA } from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../contexts/cart';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { UPDATE_JOBSITE_FAVORITE_LIST } from '../../../../aem-core-components/queries/mutation_update_jobsite_favorite';
import { MEDIA_TYPE } from '../../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import useMedia from '../../../../hooks/useMedia';
import ArrowLeftSvg from '../../../../resources/images/arrow-ios-left.svg';
import CloseIcon from '../../../../resources/images/close.svg';
import { useFilterState } from '../../../cap';
import Button from '../../atoms/button/button';
import { Tab, Tabs } from '../../atoms/tabs';
import { AUTHORITY_TYPE } from '../../constants';
import { getProjectsFromCookie } from '../../utils/commonUtils';
import { logError } from '../../utils/logger';
import SideDrawerModal from '../modals/SideDrawerModal';
import AllJobsites from './AllJobsites';
import FavoriteJobsites from './FavoriteJobsites';
import './jobsitesListingModal.scss';

const JobsitesListingModal = ({ isModalOpen, onModalClose, onJobsiteSelected, selectedProjectId, openFavoritesTab }) => {
    const intl = useIntl();
    const [updateJobsiteFavoriteList] = useMutation(UPDATE_JOBSITE_FAVORITE_LIST);
    const [{ accountProjects, allAccountProjects, isProjectsLoading, isJobSiteLoaded, favoriteJobsiteListData, isFavoritesLoading }, { dispatch, getAccountProjectsDotCom }] = useUserContext();
    const projectInfoCookies = getProjectsFromCookie();
    const [{ recommendedJobsites }, filterDispatch] = useFilterState();
    const [{ userAccount }] = useCartState();
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedJobsite, setSelectedJobsite] = useState(null);
    const [selTabIndex, setSelTabIndex] = useState(openFavoritesTab);
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const [favoriteListData, setFavoriteListData] = useState(favoriteJobsiteListData);
    const mediaType = useMedia();

    useEffect(() => {
        try {
            const searchedStoredKeyword = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.JOBSITE_SEARCH_KEYWORD);
            if (!sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISJOBSITESFETCHED)) {
                dispatch({ type: 'beginProjectsLoading' });
                getAccountProjectsDotCom(projectInfoCookies?.CurrentWynneAccount);
            } else if (searchedStoredKeyword?.length > 2) {
                /** when jobsite search keyword is present in local storage, ProjectSearch calls getAccountProjectsDotCom api
                 *  and when jobsite search keyword length <= 2, the account project is not filtered
                 *  so setting loading as true when keyword is present and length > 2
                 */
                dispatch({ type: 'beginProjectsLoading' });
                dispatch({ type: 'setAccountProjects', accountProjects: [] });
            }
        } catch (err) {
            dispatch({ type: 'endProjectsLoading' });
            logError(err, false, 'JobsitesListingModal - useEffect', []);
        }
    }, []);

    useEffect(() => {
        setSelectedValue(selectedProjectId);
    }, [selectedProjectId]);

    const onChooseJobsiteHandler = () => {
        onJobsiteSelected(selectedJobsite);
    };

    const jobsiteDrawerHeading = () => {
        return mediaType !== MEDIA_TYPE.DESKTOP ? (
            <>
                <Button
                    className="back-icon"
                    onClick={onModalClose}
                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                    tabindex="0">
                    <ArrowLeftSvg aria-hidden={true} />

                    {intl.formatMessage({ id: 'common:back' })}
                </Button>

                <Button
                    className="popup__closeicon"
                    onClick={onModalClose}
                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                    tabindex="0">
                    <CloseIcon aria-hidden={true} />
                </Button>
            </>
        ) : (
            <>
                <h5>{intl.formatMessage({ id: 'cap:choose-a-jobsite' })}</h5>
                <Button
                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                    onClick={onModalClose}>
                    <CloseIcon />
                </Button>
            </>
        );
    };
    const getSelectedTabRecordLableInfo = () => {
        if (selectedValue) {
            if (selTabIndex === 0) {
                return accountProjects?.length;
            } else {
                return favoriteJobsiteListData?.length;
            }
        }
        return 0;
    }

    const drawerFooterLabel = (selectedTabRecords) => {
        return selectedTabRecords ? intl.formatMessage({ id: 'cap:choose-this-jobsite' }) : intl.formatMessage({ id: 'close-account' });
    };

    const drawerFooter = () => {
        const selectedTabRecords = getSelectedTabRecordLableInfo();
        return (
            <Button
                className="modal-footer-button"
                buttonAriaLabel={drawerFooterLabel(selectedTabRecords)}
                onClick={() => selectedTabRecords ? onChooseJobsiteHandler() : onModalClose()}>
                {drawerFooterLabel(selectedTabRecords)}
            </Button>
        );
    };

    const handleFavoriteClick = async (rmJobsiteIdVal, event) => {
        const rmJobsiteId = rmJobsiteIdVal?.trim();
        event.stopPropagation();
        try {
            if (isFavoritesLoading) return;
            const COMPANY_ID = parseInt(localStorage.getItem('companyID')) || 1;
            dispatch({ type: SET_IS_FAVORITES_LOADING, payload: true });
            const { data, error } = await updateJobsiteFavoriteList({
                variables: {
                    jobsite_id: rmJobsiteId,
                    account_id: userAccount?.accountNumber?.toString(),
                    company_id: COMPANY_ID
                },
                fetchPolicy: 'network-only'
            });

            if (data?.addCustomerJobsiteToFavorites) {
                var accIndex = accountProjects?.findIndex(item => item?.rmJobsiteId?.trim() === rmJobsiteId);
                var recommededJobsitesIndex = recommendedJobsites?.findIndex(item => item?.rmJobsiteId?.trim() === rmJobsiteId);

                if (accountProjects[accIndex].isFavorite) {
                    accountProjects[accIndex].isFavorite = false;
                    const updatedFavoriteListData = favoriteJobsiteListData?.filter(item => item?.rmJobsiteId?.trim() !== rmJobsiteId);
                    dispatch({ type: SET_JOBSITE_FAVORITE_LIST_DATA, payload: updatedFavoriteListData });
                    setFavoriteListData(updatedFavoriteListData);
                    if (recommededJobsitesIndex !== -1) {
                        recommendedJobsites[recommededJobsitesIndex].isFavorite = false;
                    }
                    sessionStorage.setItem(
                        STORAGE_CONFIG.SESSION_STORAGE.RECOMMENDED_JOBSITE,
                        JSON.stringify(recommendedJobsites)
                    );
                }
                else {
                    accountProjects[accIndex].isFavorite = true;
                    const updatedFavoriteListData = [...favoriteJobsiteListData, accountProjects[accIndex]];
                    dispatch({ type: SET_JOBSITE_FAVORITE_LIST_DATA, payload: updatedFavoriteListData });
                    setFavoriteListData(updatedFavoriteListData);
                    if (recommededJobsitesIndex !== -1) {
                        recommendedJobsites[recommededJobsitesIndex].isFavorite = true;
                    }
                    sessionStorage.setItem(
                        STORAGE_CONFIG.SESSION_STORAGE.RECOMMENDED_JOBSITE,
                        JSON.stringify(recommendedJobsites)
                    );
                }
            }
            dispatch({ type: SET_IS_FAVORITES_LOADING, payload: false });
        }
        catch (error) {
            dispatch({ type: SET_IS_FAVORITES_LOADING, payload: false });
            logError(error, false, 'handleFavoriteClick');
        }
    };

    const cardStyle = data => {
        return (
            <div className="card">
                {selectedValue === data?.jobNumber && (
                    <div className="card__tag">{intl.formatMessage({ id: 'common:selected' })}</div>
                )}
                <small className="card__title">
                    {data?.jobName}
                    {isP2PUser && (
                        <span data-testid={`star-icon-${data?.rmJobsiteId}`} onClick={(event) => handleFavoriteClick(data?.rmJobsiteId, event)}>
                            {!data?.isFavorite ? (
                                <StarIcon className="button_icon empty-star" aria-hidden={true} tabIndex={'-1'} />
                            ) : (
                                <StarSolidIcon className="button_icon filled-star" aria-hidden={true} tabIndex={'-1'} />
                            )}
                        </span>
                    )}
                </small>
                <small className="card__content">{data?.address1}</small>
                <small>
                    {data?.city}, {data?.state} {data?.zip}
                </small>
            </div>
        );
    };

    const drawerContent = () => {
        if (isP2PUser) {
            return (
                <div className='choose-jobsite-container'>
                    {mediaType !== MEDIA_TYPE.DESKTOP && (
                        <h5 className="jobsites-list-title-text">{intl.formatMessage({ id: 'cap:choose-a-jobsite' })}</h5>
                    )}
                    <Tabs selTabIndex={selTabIndex} onTabChange={setSelTabIndex} tabWrapClasses='jobsite-tabs'>
                        <Tab title={`${intl.formatMessage({ id: 'account-tab-label' })} (${allAccountProjects?.length})`}
                            customButtonAriaLabel={`${intl.formatMessage({ id: 'account-tab-label' })} (${allAccountProjects?.length})`} >
                            <AllJobsites
                                isProjectsLoading={isProjectsLoading}
                                accountProjects={accountProjects}
                                recommendedJobsites={recommendedJobsites}
                                isJobSiteLoaded={isJobSiteLoaded}
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                                setSelectedJobsite={setSelectedJobsite}
                                cardStyle={cardStyle}
                                jobsiteListLabel={intl.formatMessage({ id: 'cap:all-jobsites' })}
                                isModalOpen={isModalOpen}
                                selectedProjectId={selectedProjectId}
                            />
                        </Tab>
                        <Tab title={`${intl.formatMessage({ id: 'favorite-tab-label' })} (${isProjectsLoading ? 0 : favoriteJobsiteListData?.length})`}
                            customButtonAriaLabel={`${intl.formatMessage({ id: 'favorite-tab-label' })} (${isProjectsLoading ? 0 : favoriteJobsiteListData?.length})`}>
                            <FavoriteJobsites
                                isProjectsLoading={isProjectsLoading}
                                favoriteJobsiteListData={favoriteJobsiteListData}
                                favoriteListData={favoriteListData}
                                setFavoriteListData={setFavoriteListData}
                                isJobSiteLoaded={isJobSiteLoaded}
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                                setSelectedJobsite={setSelectedJobsite}
                                cardStyle={cardStyle}
                                jobsiteListLabel={intl.formatMessage({ id: 'cap:favorite-jobsites' })}
                                isModalOpen={isModalOpen}
                            />
                        </Tab>
                    </Tabs>
                </div>
            )
        }

        return (
            <AllJobsites
                isProjectsLoading={isProjectsLoading}
                accountProjects={accountProjects}
                recommendedJobsites={recommendedJobsites}
                isJobSiteLoaded={isJobSiteLoaded}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                setSelectedJobsite={setSelectedJobsite}
                cardStyle={cardStyle}
                jobsiteListLabel={intl.formatMessage({ id: 'cap:all-jobsites' })}
                isModalOpen={isModalOpen}
                selectedProjectId={selectedProjectId}
            />
        )
    };


    return (
        <SideDrawerModal
            modalHeaderClass={mediaType !== MEDIA_TYPE.DESKTOP && 'create-modal-header'}
            header={jobsiteDrawerHeading()}
            isModalOpen={isModalOpen}
            footer={drawerFooter()}
            content={drawerContent()}
            modalContentClass={`jobsite-drawer-content`}
            handleModalToggle={onModalClose}
        />
    );
};

JobsitesListingModal.propTypes = {
    isModalOpen: bool,
    onModalClose: func,
    onJobsiteSelected: func,
    selectedProjectId: string,
    openFavoritesTab: number,
};

JobsitesListingModal.defaultProps = {
    isModalOpen: true,
    onModalClose: () => { },
    onJobsiteSelected: () => { },
    selectedProjectId: '',
    openFavoritesTab: 0,
};

export default React.memo(JobsitesListingModal);
