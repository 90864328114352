import { bool, func, node, string } from 'prop-types';
import React, { useContext, useEffect, useId } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import { ModalContext } from '../../../../../aem-core-components/context/ModalContext';
import AlertCircle from '../../../../../resources/images/alert-yellow-circle.svg';
import CloseIcon from '../../../../../resources/images/close.svg';
import Button from '../../../atoms/button';
import './alertModal.scss';

const AlertModal = props => {
    const {
        title,
        content,
        handleCloseModal,
        titleDataLocator,
        closeIconDataLocator,
        isWarning,
        alertClassname,
        alertTooltipDataLocator,
        isAddToCart,
        isModalOpen,
        isModalWithButtonOpen,
        icon,
        role,
        customModalClass,
        showClose,
        customClass
    } = props;
    const intl = useIntl();
    const body = document.querySelector('body');
    const liveChatButton = document.querySelector('.chat-button');
    // useOutsideAlerter(popupDialog);
    const useModalState = () => useContext(ModalContext);
    const { closeModal } = useModalState();
    const addToCartModalId = useId();

    useEffect(() => {
        Modal.setAppElement('.root');
    }, []);
    useEffect(() => {
        const title = document.querySelector('.popup__title');

        if (isModalOpen) {
            body.classList.add('overflow-hidden');
            title?.focus();
            if (liveChatButton) {
                liveChatButton.ariaHidden = 'true';
                liveChatButton.tabIndex = '-1';
            }
        }

        const container = document.querySelector('div .popup');
        if (container) {
            container.focus();
            container.addEventListener('focusout', ev => {
                if (!container.contains(ev.relatedTarget)) {
                    container.focus();
                }
            });
        }
    }, [isModalOpen]);

    const handleClose = () => {
        closeModal();
        handleCloseModal && handleCloseModal();
        body.classList.remove('overflow-hidden');
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '12px',
            padding: '0',
            border: '0',
            overflow: 'visible'
        }
    };

    const renderModal = () => {
        return (
            <div>
                <div className={`popup__titlewrap ${customClass}`}>
                    <div className="popup__title__container">
                        {isWarning ? (
                            <span className="popup__warning__icon" aria-hidden={true}>
                                {icon}
                            </span>
                        ) : (
                            <span className="popup__warning__icon" aria-hidden={true}>
                                <AlertCircle data-testid={alertTooltipDataLocator} />
                            </span>
                        )}
                        <h6
                            className="popup__titlecolor popup__title"
                            data-testid={titleDataLocator}
                            id={addToCartModalId}>
                            {title}
                        </h6>
                    </div>
                    {!showClose && (
                        <Button
                            className="popup__crossicon"
                            onClick={handleClose}
                            dataTestid={closeIconDataLocator}
                            customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}>
                            <CloseIcon aria-hidden="true" tabIndex={'-1'} />
                        </Button>
                    )}
                </div>
                <div className="popup__content">{content}</div>
            </div>
        );
    };

    const renderAddedToCartModal = () => {
        return (
            <div className={`modal-addtocart ${customModalClass}`}>
                <div className="modal-addtocart__title-wrap">
                    <h6 className="popup__titlecolor popup__title" data-testid={titleDataLocator} id={addToCartModalId}>
                        {title}
                    </h6>
                    <Button
                        className="popup__crossicon"
                        onClick={handleClose}
                        data-testid={closeIconDataLocator}
                        customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}>
                        <CloseIcon aria-hidden="true" tabIndex={'-1'} />
                    </Button>
                </div>

                <div className="popup__content">{content}</div>
            </div>
        );
    };
    return (
        <>
            {isAddToCart ? (
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={handleClose}
                    style={customStyles}
                    role="none"
                    aria={{
                        labelledby: `${addToCartModalId}`
                    }}>
                    {renderAddedToCartModal()}
                </Modal>
            ) : (
                <Modal
                    isOpen={isModalOpen || isModalWithButtonOpen}
                    onRequestClose={handleClose}
                    className={`popup__dialog popup__alert ${alertClassname && alertClassname}`}
                    role="none"
                    aria={{
                        labelledby: `${addToCartModalId}`
                    }}>
                    {renderModal()}
                </Modal>
            )}
        </>
    );
};

export default React.memo(AlertModal);

AlertModal.propsType = {
    title: string,
    content: node,
    handleCloseModal: func,
    titleDataLocator: string,
    closeIconDataLocator: string,
    isWarning: bool,
    alertClassname: string,
    alertTooltipDataLocator: string,
    isAddToCart: bool,
    isModalOpen: bool,
    showClose: bool,
    customClass: string
};

AlertModal.defaultProps = {
    title: '',
    content: '',
    handleCloseModal: () => {},
    titleDataLocator: '',
    closeIconDataLocator: '',
    isWarning: false,
    alertClassname: '',
    alertTooltipDataLocator: '',
    isAddToCart: false,
    isModalOpen: false,
    showClose: false,
    customClass: ''
};
