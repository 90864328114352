import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { bool, number, shape, string } from 'prop-types';
import Alert from '../../../atoms/alert/alert';
import AlertIcon from '../../../../../resources/images/alert-triangle-red.svg';
import { PRODUCT_CHANGE_STATUS } from '../constants';

const ProductStatusChangeAlert = ({ isProductStatusChanged, status, className }) => {
    const intl = useIntl();

    const getUnavailableAlertMsg = () => {
        if (isProductStatusChanged) {
            return intl.formatMessage({ id: 'common:no-longer-available' });
        } else {
            return intl.formatMessage({ id: 'common:unavailable-for-your-rental' });
        }
    };

    const renderAlert = () => {
        switch (status) {
            case PRODUCT_CHANGE_STATUS.UNAVAILABLE:
                return (
                    <Alert
                        message={getUnavailableAlertMsg()}
                        type="error"
                        icon={<AlertIcon tabindex={-1} aria-hidden={true} />}
                        localStyle={className}
                        id={'alert-msg'}
                        role={'none'}
                    />
                );
            default:
                return <></>;
        }
    };

    return renderAlert();
};

ProductStatusChangeAlert.propTypes = {
    isProductStatusChanged: bool,
    status: string,
    className: string
};

ProductStatusChangeAlert.defaultProps = {
    isProductStatusChanged: false,
    status: '',
    className: ''
};

export default memo(ProductStatusChangeAlert);
