import { useMutation } from '@apollo/client';
import { useAwaitQuery } from '../aem-core-components/utils/hooks';
import { useCartState } from '../contexts/cart';
import MUTATION_REMOVE_ITEM from '../aem-core-components/queries/mutation_remove_item.graphql';
import CART_DETAILS_QUERY from '../aem-core-components/queries/query_cart_details.graphql';
import { removeItemFromCart } from '../aem-core-components/actions/cart';
import { SET_IS_CART_LOADING, SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED } from '../aem-core-components/actions/constants';

export default props => {
    const { item } = props;
    const [{ cartId }, dispatch] = useCartState();
    const [removeItemMutation] = useMutation(MUTATION_REMOVE_ITEM);
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);

    const editItem = () => {
        dispatch({ type: 'beginEditing', item: item });
    };

    const removeItem = async itemUid => {
        try {
            dispatch({ type: 'beginLoading' });
            const itemQuantity = await removeItemFromCart({
                cartId,
                itemUid,
                dispatch,
                cartDetailsQuery,
                removeItemMutation
            });
            if (!itemQuantity || itemQuantity?.length === 0) {
                dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
                dispatch({
                    type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                    key: 'isEstimateOnCartOpenRequired',
                    value: false
                });
            }
            dispatch({ type: 'endLoading' });
            return itemQuantity;
        } catch (er) {
            dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
            dispatch({
                type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                key: 'isEstimateOnCartOpenRequired',
                value: false
            });
            dispatch({ type: 'endLoading' });
        }
    };

    return [{}, { removeItem, editItem }];
};
