import React, { useEffect, useRef, useState, useId } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import {
    POP_CART_STACK,
    SET_LOCATION_DATA,
    SET_VIEW_CART_FIELDS,
    UPDATE_PROJECT
} from '../../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../../contexts/cart';
import ChooseAccount from '../../chooseAccount/ChooseAccount';
import { VIEW_CART } from '../../../../../constants/cartConstants';
import ExistingAccounts from '../../existingAccount';
import NewAddress from '../../newAddress';
import { useAnalyticsContext } from '../../../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../../../constants/analyticsConstants/Variables';
import { ENTER_KEY, SCREEN_NAME } from '../../../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../../../constants/storageConfig';
import { USER_TYPE } from '../../../../../constants/userDetailsConstants';
import { useCheckUser, useCheckAuthorityType } from '../../../../../hooks/useCheckUser';
import useComputeLocation from '../../../../../hooks/useComputeLocation';
import { usePageType } from '../../../../../hooks/usePageType';
import useAnalytics from '../../../../../hooks/useAnalytics';
import Close from '../../../../../resources/images/close.svg';
import { locationOverlayDataLocators } from '../../../../pdp/location/LocationOverlayModal/dataLocators';
import Button from '../../../atoms/button/button';
import { isCCPage, isCheckoutPage } from '../../../utils/commonUtils';
import { logError } from '../../../utils/logger';
import { getlatLongByAddress } from '../../location-autocomplete/api/getLocations';
import ToggleTabs from '../../ToggleTabs/ToggleTabs';
import './fixedHeaderFooterModal.scss';
import { useFilterState } from '../../../../cap';
import { AUTHORITY_TYPE } from '../../../../../components/global/constants';

const FixedHeaderFooter = props => {
    const intl = useIntl();
    const {
        setShowProjectModal,
        showAccountOverlayModal,
        showLocationOverlayModal,
        handleLocationOverlay,
        handleAccountOverlay,
        screenName,
        setIsCreditNewAddress,
        handleBlockedAccount = () => { }
    } = props;
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            border: 'none',
            borderRadius: '12px'
        }
    };
    const locationModalId = useId();
    const [{ isCreditNewAddress, isOpen }, dispatch] = useCartState();
    const [{ projectDetails, viewCart }, filterDispatch] = useFilterState();
    const userType = useCheckUser();
    const [selectedButton, setSelectedButton] = useState(
        screenName === SCREEN_NAME.PDP_SCREEN ? !isCreditNewAddress : true
    );
    const {
        sendEventsForLocationAction,
        sendEventsForAddressErrorAction,
        sendEventsForLocationFormSubmitComplete,
        sendEventsForLocationFormSubmit
    } = useAnalyticsContext();
    const [{ payloadEcommerceLocationActionAnalytics, payloadEcommerceLocationActionSubmitted }] = useAnalytics();
    const [selectedProjectData, setSelectedProjectData] = useState({});
    const [autoCompleteErrorMsg, setAutoCompleteErrorMsg] = useState('');
    const [manualAddress, setManualAddress] = useState('');
    const [autoCompleteAddressSelected, setAutoCompleteAddressSelected] = useState('');
    const [locationByPlaces, setLocationByPlaces] = useState('');
    const [addressChangedByGeocode, setAddressChangedByGeocode] = useState(false);
    const [globalLocation, setGlobalLocation] = useState({
        location: viewCart?.location || '',
        lat: viewCart?.lat || '',
        long: viewCart?.long || '',
        jobSiteAddr2: viewCart?.jobSiteAddr2 || '',
        jobSiteCity: viewCart?.jobSiteCity || '',
        jobSiteState: viewCart?.jobSiteState || '',
        jobSiteZip: viewCart?.jobSiteZip || '',
        pc: viewCart?.pc || '',
        pcLat: viewCart?.pcLat || '',
        pcLong: viewCart?.pcLong || ''
    });

    const userCompanyID = parseInt(localStorage.getItem('companyID')) || 1;
    const { sendEventsForClick } = useAnalyticsContext();
    const pageType = usePageType();
    const {
        computeInstore,
        setCanadaRestrictStateError,
        canadaRestrictStateError,
        handleManualUpdatedInstoreAddress,
        updateManualLocationInContext
    } = useComputeLocation();
    const authorityType = useCheckAuthorityType();

    const [fixedHeaderModalOpen, setFixedHeaderModalOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const FixedHeaderModalRef = useRef(null);
    function afterOpenModal() {
        setFixedHeaderModalOpen(!fixedHeaderModalOpen);
    }
    const handleIsSticky = e => {
        const scrollTop = FixedHeaderModalRef?.current?.scrollTop;
        scrollTop >= 10 ? setIsSticky(true) : setIsSticky(false);
    };

    useEffect(() => {
        setGlobalLocation({
            location: viewCart?.location || '',
            lat: viewCart?.lat || '',
            long: viewCart?.long || '',
            jobSiteAddr2: viewCart?.jobSiteAddr2 || '',
            jobSiteCity: viewCart?.jobSiteCity || '',
            jobSiteState: viewCart?.jobSiteState || '',
            jobSiteZip: viewCart?.jobSiteZip || '',
            pc: viewCart?.pc || '',
            pcLat: viewCart?.pcLat || '',
            pcLong: viewCart?.pcLong || ''
        });
    }, [viewCart?.lat]);

    useEffect(() => {
        if (fixedHeaderModalOpen) {
            FixedHeaderModalRef?.current?.addEventListener('scroll', handleIsSticky);
        }
        return () => {
            FixedHeaderModalRef?.current?.removeEventListener('scroll', handleIsSticky);
        };
    }, [fixedHeaderModalOpen]);

    useEffect(() => {
        setCanadaRestrictStateError('');
    }, [manualAddress]);

    useEffect(() => {
        if (showLocationOverlayModal) {
            document.body.classList.add('overflow-hidden');
        }
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, [showLocationOverlayModal]);

    const handleToggle = currentSelected => {
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISNEWADDRESSONOVERLAY, !currentSelected);
        setSelectedButton(currentSelected);
        if (
            JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY)) &&
            !isCheckoutPage()
        ) {
            sendEventsForLocationAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_STARTED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_NAME_ADDRESS_TYPE,
                payloadEcommerceLocationActionAnalytics()
            );
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
        }
    };

    const handleBackLink = async () => {
        if (!handleBlockedAccount()) {
            let error = false;
            let userAddressData;
            if (!selectedButton && autoCompleteAddressSelected === '') {
                if (manualAddress?.trim() === '') {
                    setAutoCompleteErrorMsg(
                        intl.formatMessage({ id: 'cart-header:location-autocomplete-error-mesage-2' })
                    );
                    error = true;
                    handleSuccessErrorEvents(globalLocation, error);
                    return;
                }
                if (manualAddress !== globalLocation?.location) {
                    let localLocation;
                    if (manualAddress?.trim()) {
                        const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
                        localLocation = await handleManualUpdatedInstoreAddress(manualAddress, true, companyID);
                    }
                    if (!localLocation) {
                        setAutoCompleteErrorMsg(
                            intl.formatMessage({ id: 'cart-header:location-autocomplete-error-mesage-2' })
                        );
                        error = true;
                        handleSuccessErrorEvents(localLocation, error);
                        return;
                    } else {
                        setAutoCompleteErrorMsg('');
                        if (manualAddress.toLowerCase() !== localLocation?.location.toLowerCase()) {
                            setAddressChangedByGeocode(true);
                            setManualAddress(localLocation?.location);
                            setGlobalLocation({
                                location: localLocation?.location,
                                lat: localLocation?.lat,
                                long: localLocation?.long,
                                jobSiteAddr2: localLocation?.jobSiteAddr2,
                                jobSiteCity: localLocation?.jobSiteCity,
                                jobSiteState: localLocation?.jobSiteState,
                                jobSiteZip: localLocation?.jobSiteZip,
                                pc: '',
                                pcLat: '',
                                pcLong: ''
                            });
                            return;
                        } else {
                            setManualAddress(localLocation?.location);
                            const pcVal = await updateManualLocationInContext(localLocation);
                            setIsCreditNewAddress && setIsCreditNewAddress(!selectedButton);
                            userAddressData = { ...localLocation, ...pcVal };
                        }
                    }
                } else {
                    setManualAddress(globalLocation?.location);
                    const pcVal = await updateManualLocationInContext(globalLocation);
                    setIsCreditNewAddress && setIsCreditNewAddress(!selectedButton);
                    userAddressData = { ...globalLocation, ...pcVal };
                }
                try {
                    sendEventsForClick(
                        VARIABLE_CONFIG.EVENT.UAEVENT,
                        VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                        pageType,
                        VARIABLE_CONFIG.EVENT_ACTION.SELECT_LOCATION,
                        userCompanyID == 2 ? `Canada ${globalLocation?.jobSiteZip}` : `US ${globalLocation?.jobSiteZip}`
                    );
                } catch (error) {
                    logError(error, false, 'handleBackLink');
                }
            } else if (!selectedButton && autoCompleteAddressSelected) {
                setIsCreditNewAddress && setIsCreditNewAddress(!selectedButton);
                const localLocation = await computeInstore(locationByPlaces, autoCompleteAddressSelected, '', true);

                if (!localLocation) {
                    setAutoCompleteErrorMsg(intl.formatMessage({ id: 'order-details:address-error-label' }));
                    error = true;
                    handleSuccessErrorEvents(localLocation, error);
                    return;
                } else {
                    setAutoCompleteErrorMsg('');
                    setManualAddress(localLocation?.location);
                    filterDispatch({
                        type: SET_LOCATION_DATA,
                        pc: localLocation?.pc,
                        location: localLocation?.location,
                        jobSiteCity: localLocation?.jobSiteCity,
                        jobSiteState: localLocation?.jobSiteState,
                        jobSiteZip: localLocation?.jobSiteZip,
                        lat: localLocation?.lat,
                        long: localLocation?.long,
                        jobSiteAddr2: localLocation?.jobSiteAddr2,
                        pcLat: localLocation?.pcLat,
                        pcLong: localLocation?.pcLong
                    });
                    userAddressData = localLocation;
                }
                try {
                    sendEventsForClick(
                        VARIABLE_CONFIG.EVENT.UAEVENT,
                        VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                        pageType,
                        VARIABLE_CONFIG.EVENT_ACTION.SELECT_LOCATION,
                        userCompanyID == 2 ? `Canada ${localLocation?.jobSiteZip}` : `US ${localLocation?.jobSiteZip}`
                    );
                } catch (error) {
                    logError(error, false, 'handleBackLink');
                }
            } else {
                const {
                    address1,
                    latitude,
                    longitude,
                    city,
                    state,
                    zip,
                    locationPC,
                    contactName,
                    contactPhone,
                    deliveryInstructions,
                    customerPO
                } = selectedProjectData || '';
                const addressTosend = address1 || `${city}, ${state}`; // If no address in addressList
                let projectLat = '';
                let projectLong = '';
                setIsCreditNewAddress && setIsCreditNewAddress(!selectedButton);
                const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
                if (Object.keys(selectedProjectData).length) {
                    if (!latitude || !longitude) {
                        const response = await getlatLongByAddress(companyID, `${address1}, ${city}, ${state}, ${zip}`);
                        if (response?.data?.results?.length > 0) {
                            projectLat = response?.data?.results[0]?.geometry?.location.lat;
                            projectLong = response?.data?.results[0]?.geometry?.location.lng;
                        }
                    } else {
                        projectLat = latitude;
                        projectLong = longitude;
                    }

                    filterDispatch({
                        type: UPDATE_PROJECT,
                        projectName: selectedProjectData?.jobName,
                        projectAddress1: addressTosend,
                        projectAddress2:
                            selectedProjectData?.city + ' ' + selectedProjectData.state + ' ' + selectedProjectData.zip,
                        selectedProjectJobId: selectedProjectData?.jobNumber,
                        selectedRMJobId: selectedProjectData?.rmJobsiteId,
                        selectedProjectLatititude: projectLat,
                        selectedProjectLongitude: projectLong,
                        selectedProjectState: state,
                        selectedProjectCity: city,
                        selectedProjectZip: zip,
                        locationPC: locationPC,
                        primaryContactName: contactName,
                        phoneNumber: contactPhone,
                        accessNotes: deliveryInstructions,
                        poNumber: customerPO,
                        isValueUpdated: true,
                        CJDLVY: selectedProjectData?.CJDLVY
                    });
                    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, selectedProjectData?.CJDLVY);
                    userAddressData = selectedProjectData;
                    try {
                        sendEventsForClick(
                            VARIABLE_CONFIG.EVENT.UAEVENT,
                            VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                            pageType,
                            VARIABLE_CONFIG.EVENT_ACTION.SELECT_LOCATION,
                            userCompanyID == 2 ? `Canada ${selectedProjectData?.zip}` : `US ${selectedProjectData?.zip}`
                        );
                    } catch (error) {
                        logError(error, false, 'handleBackLink');
                    }
                } else {
                    if (projectDetails?.selectedProjectJobId) {
                        if (!projectDetails?.selectedProjectLatititude || !projectDetails?.selectedProjectLongitude) {
                            const response = await getlatLongByAddress(
                                companyID,
                                `${projectDetails?.projectAddress1}, ${projectDetails?.selectedProjectCity}, ${projectDetails?.selectedProjectState}, ${projectDetails?.selectedProjectZip}`
                            );
                            if (response?.data?.results?.length > 0) {
                                projectLat = response?.data?.results[0]?.geometry?.location.lat;
                                projectLong = response?.data?.results[0]?.geometry?.location.lng;
                            }
                        } else {
                            projectLat = projectDetails?.selectedProjectLatititude;
                            projectLong = projectDetails?.selectedProjectLongitude;
                        }
                        filterDispatch({
                            type: UPDATE_PROJECT,
                            projectName: projectDetails?.projectName,
                            projectAddress1: projectDetails?.projectAddress1,
                            projectAddress2:
                                projectDetails?.city + ' ' + projectDetails.state + ' ' + projectDetails.zip,
                            selectedProjectJobId: projectDetails?.selectedProjectJobId,
                            selectedRMJobId: projectDetails?.selectedRMJobId,
                            selectedProjectLatititude: projectLat,
                            selectedProjectLongitude: projectLong,
                            selectedProjectState: projectDetails?.selectedProjectState,
                            selectedProjectCity: projectDetails?.selectedProjectCity,
                            selectedProjectZip: projectDetails?.selectedProjectZip,
                            locationPC: projectDetails?.locationPC,
                            primaryContactName: projectDetails?.contactName,
                            phoneNumber: projectDetails?.contactPhone,
                            accessNotes: projectDetails?.accessNotes,
                            poNumber: projectDetails?.poNumber,
                            isValueUpdated: true,
                            CJDLVY: projectDetails?.CJDLVY
                        });
                        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, projectDetails?.CJDLVY);
                        try {
                            sendEventsForClick(
                                VARIABLE_CONFIG.EVENT.UAEVENT,
                                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                                pageType,
                                VARIABLE_CONFIG.EVENT_ACTION.SELECT_LOCATION,
                                userCompanyID == 2 ? `Canada ${projectDetails?.zip}` : `US ${projectDetails?.zip}`
                            );
                        } catch (error) {
                            logError(error, false, 'handleBackLink');
                        }
                    }
                }
            }
            handleSuccessErrorEvents(userAddressData, error);
            if (!error) {
                if (setShowProjectModal) {
                    setShowProjectModal(false);
                } else if (handleLocationOverlay) {
                    handleLocationOverlay(false);
                } else {
                    dispatch({ type: POP_CART_STACK });
                }
            }
            if (authorityType !== AUTHORITY_TYPE.P2P) {
                handleChange(VIEW_CART.SHOW_CREATE_PROJECT, projectDetails?.projectName ? !selectedButton : true);
                handleChange(VIEW_CART.CREATE_PROJECT_SOURCE, 'minicart');
            }
        } else {
            handleLocationOverlay(false);
        }
        if (userType === USER_TYPE.CREDIT && isCCPage()) {
            if (isOpen) {
                sessionStorage.setItem('isOpen', true);
            }
            window.location.reload();
        }
    };

    const handleSuccessErrorEvents = (userAddressData, error) => {
        try {
            if (!isCheckoutPage() && (userAddressData || error)) {
                sendEventsForLocationFormSubmit(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_EVENT,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_LINK_NAME,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_LINK_TYPE,
                    payloadEcommerceLocationActionSubmitted(userAddressData, error)
                );
                if (error) {
                    sendEventsForAddressErrorAction(
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_ADDRESS_EVENT,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_ADDRESS_FIELD,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_ADDRESS_ERROR,
                        payloadEcommerceLocationActionAnalytics(error)
                    );
                } else {
                    sendEventsForLocationFormSubmitComplete(
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_LOCATION_SUBMITTED_EVENT,
                        payloadEcommerceLocationActionSubmitted(userAddressData)
                    );
                    sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
                }
            }
        } catch (error) {
            logError(error, false, 'handleSuccessErrorEvents');
        }
    };
    const onEnterKeyPressed = e => {
        if (e.key === ENTER_KEY) {
            handleBackLink();
        }
    };

    const handleChange = (key, value) => {
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key, value });
    };

    const setSelectedProjectId = data => {
        setSelectedProjectData(data);
    };
    const closeHandler = () => {
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISNEWADDRESSONOVERLAY, isCreditNewAddress);
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
        handleBlockedAccount();
        handleLocationOverlay(false);
        if (userType === USER_TYPE.CREDIT && isCCPage()) {
            window.location.reload();
        }
    };

    return (
        <>
            <Modal
                isOpen={showLocationOverlayModal}
                style={customStyles}
                onAfterOpen={afterOpenModal}
                role="none"
                aria={{
                    labelledby: `${locationModalId}`
                }}>
                <div
                    className={`modal-locationdialog-wrap ${showAccountOverlayModal ? 'account-overlay' : ''} ${selectedButton
                        ? 'modal-searchproject-dialog modal-searchProject-mobileFix'
                        : 'modal-searchbyzip-dialog'
                        }`}>
                    {' '}
                    <div className="modal-content">
                        {showAccountOverlayModal ? (
                            <ChooseAccount
                                source={VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY}
                                handleAccountOverlay={handleAccountOverlay}
                                screenName={screenName}
                            />
                        ) : (
                            <>
                                <div
                                    className={`modal-content-fixed-header ${isSticky ? 'modal-content-header-sticky' : ''
                                        }`}>
                                    <h5
                                        className="modal-content-heading-label"
                                        data-testid={
                                            locationOverlayDataLocators.locationoverlay_whatsyourlocation_heading_testid
                                        }
                                        id={locationModalId}>
                                        {screenName === SCREEN_NAME.PDP_SCREEN
                                            ? intl.formatMessage({ id: 'location-overlay:whats-your-location-heading' })
                                            : intl.formatMessage({ id: 'cart-header:location-label' })}
                                    </h5>
                                    <Button
                                        data-testid={locationOverlayDataLocators.locationoverlay_cross_icon_testid}
                                        className="modal-content-close"
                                        customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                                        onClick={() => closeHandler()}>
                                        <Close aria-hidden="true" tabIndex={'-1'} />
                                    </Button>
                                </div>
                                <div className={`modal-content-fixed-body`} ref={FixedHeaderModalRef}>
                                    {screenName === SCREEN_NAME.PDP_SCREEN && (
                                        <div className="modal-content-fixed-body-top">
                                            <div
                                                className={'modal-content-subheading-label'}
                                                data-testid={
                                                    locationOverlayDataLocators.locationoverlay_whatsyourlocation_subheading_testid
                                                }
                                                tabIndex={0}>
                                                {intl.formatMessage({
                                                    id: 'location-overlay:whats-your-location-content'
                                                })}
                                            </div>
                                            <div className={'modal-content-locationOverlayModalTabs'}>
                                                <div className={'modal-content-accountTabs'}>
                                                    <ToggleTabs
                                                        toggle1Title={intl.formatMessage({
                                                            id: 'location-overlay:existing-project'
                                                        })}
                                                        toggle2Title={intl.formatMessage({
                                                            id: 'location-overlay:enter-address'
                                                        })}
                                                        isSelected={selectedButton}
                                                        toggleHandler={handleToggle}
                                                        toggle1TestId={
                                                            locationOverlayDataLocators.locationoverlay_toggleexisting_cta_testid
                                                        }
                                                        toggle2TestId={
                                                            locationOverlayDataLocators.locationoverlay_togglenew_cta_testid
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {selectedButton ? (
                                        <ExistingAccounts
                                            handleAccountOverlay={handleAccountOverlay}
                                            setSelectedProjectId={setSelectedProjectId}
                                            screenName={screenName}
                                            source={VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY}
                                        />
                                    ) : (
                                        <NewAddress
                                            screenName={screenName}
                                            setManualAddress={setManualAddress}
                                            autoCompleteErrorMsg={autoCompleteErrorMsg}
                                            setAutoCompleteErrorMsg={setAutoCompleteErrorMsg}
                                            setAddressChangedByGeocode={setAddressChangedByGeocode}
                                            addressChangedByGeocode={addressChangedByGeocode}
                                            setAutoCompleteAddressSelected={setAutoCompleteAddressSelected}
                                            setLocationByPlaces={setLocationByPlaces}
                                            canadaRestrictError={canadaRestrictStateError}
                                            source={VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY}
                                            manualAddress={manualAddress}
                                        />
                                    )}
                                </div>
                                <div
                                    className={`${selectedButton ? `modal-content-fixed-footer` : `modal-content-noshadow`
                                        }`}>
                                    <Button
                                        type="button"
                                        className={'button button-primary button-block'}
                                        onClick={() => handleBackLink()}
                                        onKeyPress={e => onEnterKeyPressed(e)}
                                        data-testid={
                                            locationOverlayDataLocators.locationoverlay_save_and_continue_cta_testid
                                        }
                                        buttonAriaLabel={intl.formatMessage({
                                            id: 'location-overlay:set-location-cta'
                                        })}>
                                        {intl.formatMessage({ id: 'location-overlay:set-location-cta' })}
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default FixedHeaderFooter;
