import { useEffect, useState } from 'react';
import { useFilterState } from '../filterContext';
import { useCartState } from '../../../contexts/cart';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { useCapUtils } from './useCapUtils';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { isDateDisabled } from '../../global/utils/commonUtils';
import { createFullAddress } from '../utils/capHelper';
import { logError } from '../../global/utils/logger';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import {
    RESET_PROJECT,
    SET_CREDIT_NEWADDR_FLAG,
    SET_LOCATION_DATA,
    UPDATE_PROJECT
} from '../../../aem-core-components/actions/constants';

export const useRentalLocation = () => {
    const [{ viewCart, projectDetails }, dispatch] = useFilterState();
    const [{ isCreditNewAddress }, cartDispatch] = useCartState();
    const { getJobsiteWithLatLong, fetchLocalLocationCoordinates } = useCapUtils();
    const { fetchLocationCoordinates } = useCheckLocationEmpty();
    const userType = useCheckUser();
    const localCompanyId = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1;
    const [companyID, setCompanyID] = useState(localCompanyId);
    const [addressText, setAddressText] = useState('');
    const [placeId, setPlaceId] = useState('');
    const [jobsiteDetails, setJobsiteDetails] = useState({});
    const [isJobsiteSelected, setIsJobsiteSelected] = useState(false);
    const [addressDetails, setAddressDetails] = useState({});
    const [locationDetails, setLocationDetails] = useState({ lat: '', long: '' });
    const [jobAndAddressState, setJobAndAddressState] = useState({
        chosenJobName: '',
        chosenAddress: ''
    });
    useEffect(() => {
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            setAddressText(
                projectDetails?.projectName ? `${projectDetails?.projectName} ${createFullAddress(projectDetails)}` : ''
            );
            setJobAndAddressState({
                chosenJobName: projectDetails?.projectName,
                chosenAddress: createFullAddress(projectDetails)
            });
            setIsJobsiteSelected(true);
            setJobsiteDetails(projectDetails);
            setLocationDetails({
                lat: projectDetails?.selectedProjectLatititude,
                long: projectDetails?.selectedProjectLongitude
            });
        } else {
            setAddressText(viewCart?.location);
            setIsJobsiteSelected(false);
            setLocationDetails({ lat: viewCart?.lat, long: viewCart?.long });
        }
    }, [isCreditNewAddress, projectDetails?.selectedProjectLatititude, viewCart?.lat, userType]);

    /* Fetch lat long for selected address or jobsite and stores data from locations call */
    const storeLocationDetails = async data => {
        const { placeId = '', jobsiteDetails = {}, isJobsiteSelected = false } = data;
        const locationCoordinates =
            (await fetchLocalLocationCoordinates(placeId, jobsiteDetails, isJobsiteSelected)) || {};
        const { lat, long, companyId, addressDetails } = locationCoordinates || {};
        setCompanyID(companyId);
        setAddressDetails(addressDetails);
        setLocationDetails({ lat, long });
        return { lat, long, companyId };
    };

    const addressSelectHandler = async (address, placeId) => {
        let locationCoordinates = null;
        if (address !== addressText) {
            setAddressText(address);
            setPlaceId(placeId);
            setIsJobsiteSelected(false);
            locationCoordinates = await storeLocationDetails({ placeId });
        }
        return locationCoordinates;
    };

    const jobsiteSelectHandler = async (jobsite, fullAddress) => {
        try {
            let locationCoordinates = null;
            const jobSiteName = jobsite?.projectName ? `${jobsite?.projectName} ${fullAddress}` : '';
            if (jobSiteName !== addressText) {
                setAddressText(jobsite?.projectName ? `${jobsite?.projectName} ${fullAddress}` : '');
                const josbiteWithLatLong = await getJobsiteWithLatLong(jobsite);
                setJobsiteDetails(josbiteWithLatLong);
                setIsJobsiteSelected(true);
                setPlaceId('');
                locationCoordinates = await storeLocationDetails({
                    jobsiteDetails: josbiteWithLatLong,
                    isJobsiteSelected: true
                });
            }
            return locationCoordinates;
        } catch (error) {
            logError(error, false, 'jobsiteSelectHandler', [jobsite, fullAddress]);
            return null;
        }
    };

    const setAddressInContext = (pc, pcLat, pcLong) => {
        dispatch({
            type: SET_LOCATION_DATA,
            ...addressDetails,
            ...locationDetails,
            location: addressText,
            pc,
            pcLat,
            pcLong
        });
    };

    const updateLocationInContext = async (pc, pcLat, pcLong) => {
        let localLocation = {};
        if (isJobsiteSelected) {
            const jobsiteWithLatLong = await getJobsiteWithLatLong(jobsiteDetails);
            // only setting pc, pcLat and pcLong for jobsites and resetting other view cart fields
            dispatch({
                type: SET_LOCATION_DATA,
                pc,
                pcLat,
                pcLong
            });
            cartDispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: false });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISNEWADDRESSONOVERLAY, false);
            dispatch({
                type: UPDATE_PROJECT,
                ...jobsiteWithLatLong
            });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, jobsiteWithLatLong?.CJDLVY);
            localLocation = {
                address1: jobsiteWithLatLong?.projectAddress1,
                address2: jobsiteWithLatLong?.projectAddress2,
                city: jobsiteWithLatLong?.selectedProjectCity,
                state: jobsiteWithLatLong?.selectedProjectState,
                zip: jobsiteWithLatLong?.selectedProjectZip,
                projectName: jobsiteWithLatLong?.projectName
            };
        } else {
            if (placeId && addressText) {
                if (userType === USER_TYPE.CREDIT) {
                    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS, true);
                    setJobsiteDetails({});
                    setAddressInContext(pc, pcLat, pcLong);
                    cartDispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: true });
                    dispatch({ type: RESET_PROJECT });
                } else {
                    setAddressInContext(pc, pcLat, pcLong);
                    cartDispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: true });
                    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS, true);
                }
                localLocation = {
                    ...addressDetails,
                    location: addressText
                };
            } else {
                dispatch({
                    type: SET_LOCATION_DATA,
                    pc,
                    location: viewCart?.location,
                    jobSiteCity: viewCart?.jobSiteCity,
                    jobSiteState: viewCart?.jobSiteState,
                    jobSiteZip: viewCart?.jobSiteZip,
                    lat: viewCart?.lat,
                    long: viewCart?.long,
                    jobSiteAddr2: viewCart?.jobSiteAddr2,
                    pcLat,
                    pcLong
                });
                if (viewCart?.pc) {
                    cartDispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: true });
                }
                localLocation = { ...viewCart };
            }
            dispatch({ type: RESET_PROJECT });
        }
        return localLocation;
    };

    const resetLocation = () => {
        let savedAddressText = '';
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            savedAddressText = projectDetails?.projectName
                ? `${projectDetails?.projectName} ${createFullAddress(projectDetails)}`
                : '';
            setJobsiteDetails(projectDetails);
            setLocationDetails({
                lat: projectDetails?.selectedProjectLatititude,
                long: projectDetails?.selectedProjectLongitude
            });
            setIsJobsiteSelected(true);
        } else {
            setPlaceId('');
            savedAddressText = viewCart?.location;
            setLocationDetails({ lat: viewCart?.lat, long: viewCart?.long });
            setIsJobsiteSelected(false);
        }
        setAddressText(savedAddressText);
        setCompanyID(parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1);
        return savedAddressText;
    };

    const checkIfDayDisabled = date => {
        const isHolidayDate = isDateDisabled(companyID);
        return isHolidayDate(date);
    };

    // check if the local address and context address are different
    const isLocationChanged = (lat = locationDetails?.lat, long = locationDetails?.long) => {
        const { localLat, localLong } = fetchLocationCoordinates();
        return localLat !== lat || localLong !== long;
    };

    return {
        addressText,
        jobsiteDetails,
        isJobsiteSelected,
        locationDetails,
        companyID,
        addressDetails,
        addressSelectHandler,
        jobsiteSelectHandler,
        updateLocationInContext,
        resetLocation,
        checkIfDayDisabled,
        isLocationChanged,
        jobAndAddressState,
        setJobAndAddressState
    };
};
