import React, { useContext, useReducer, useEffect, useState } from 'react';
import { object, func, node } from 'prop-types';
import { useCookieValue, useAwaitQuery, useQueryParams, useAuthorityToken } from '../utils/hooks';
import {
    clearAemCookies,
    fetchAccountProjects,
    updateTokensOnCC
} from '../../components/global/api/CommonResponseHandler';
import { useMutation } from '@apollo/client';
import parseError from '../utils/parseError';
import { resetCustomerCart as resetCustomerCartAction, signOutUser as signOutUserAction } from '../actions/user';
import {
    clearSentryScope,
    getEnv,
    isValidString,
    logError,
    logSentryUserId,
    logSuccess,
    logWarning,
    setSentryScope
} from '../../components/global/utils/logger';
import { handleSignout } from './../../components/global/utils/p2pUtils';

import MUTATION_REVOKE_TOKEN from '../queries/mutation_revoke_customer_token.graphql';
import MUTATION_GENERATE_TOKEN_MAGENTO from '../queries//mutation_generate_token_magento_okta.graphql';
import MUTATION_CHECK_IS_EMAIL_AVAILABLE from '../queries/mutation_check_is_email_available.graphql';
import MUTATION_GUEST_SESSION from '../queries//mutation_guest_session.graphql';
import FETCH_ACCOUNTS_PROJECTS from '../queries/mutation_fetch_accounts_projects';
import { uuid4 } from '@sentry/utils';
import axiosInstance from '../../../main/api/axiosDotComInstance';
import config from '../../components/App/config';
import {
    clearLocalStorage,
    deleteCookies,
    formatjobsiteZip,
    getURLParams,
    getUserType,
    isCCPage,
    isPunchoutPage,
    redirectSignOutURL
} from '../../components/global/utils/commonUtils';
import { CART_AGE } from '../../constants/cartConstants';
import { ERROR_MSG, SUCCESS_MSG } from '../../constants/errorMappingCodes';
import { cookieValue, deleteCookie } from '../utils/cookieUtils';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { API_CONFIG } from '../../constants/apiConfig';
import { ENV_CONFIG } from '../../constants/envConfig';
import { validateRefreshTokenMutation } from '../actions/cart/actions';
import VALIDATE_TOKEN_BY_REFRESH from '../queries/mutation_validate_token_by_refresh.graphql';
import { useCheckAuthorityType, useCheckUser } from '../../hooks/useCheckUser';
import { USER_TYPE } from '../../constants/userDetailsConstants';
import { useDidMount } from '../../hooks/useDidMount';
import GET_BUYER_ACCOUNT from '../queries/mutation_get_buyer_account.graphql';
import { AUTHORITY_TYPE } from '../../components/global/constants';
import { userInitialState, userAction } from '../../contexts/user/index';
import {
    SET_IS_JOBSITE_LOADED,
    SET_RATES_LOADING_FLAG,
    SET_ACCOUNT_FAVORITE_LIST_DATA,
    SET_ALL_ACCOUNT_PROJECTS
} from '../actions/constants';
import GET_ACCOUNT_FAVORITE_LIST from '../queries/query_get_account_favorite';
import GET_JOBSITE_FAVORITE_LIST from '../queries/query_get_jobsite_favorite';

const { PROJECT_SITE_NEW_URL, CUSTOMERS_API_URL, P2P_JOBSITE_URL } = API_CONFIG;
const UserContext = React.createContext();

const UserContextProvider = props => {
    const [userCookie, setUserCookie] = useCookieValue('cif.userToken');
    const [accessToken, setAccessToken] = useAuthorityToken('accesstoken');
    // punchout cookies start
    const [punchoutCompanyId] = useCookieValue('companyId');
    // punchout cookies end
    const [refreshToken, setRefreshToken] = useAuthorityToken('refreshtoken');
    const [sessionId, setSessionId] = useCookieValue('sessionid');
    const [sbuidCookie, setsbuidCookie] = useCookieValue('sbuid');
    const [sbaidCookie, setsbaidCookie] = useCookieValue('sbaid');
    const [guestUserId, setGuestUserId] = useCookieValue('guest_user_id');
    const [magentoTokenCookie, setMagentoTokenCookie] = useCookieValue('cif.magentoToken');
    const [guestSessionId, setGuestSessionId] = useCookieValue('guestsessionid');
    const [, setLoginToken] = useCookieValue('loginToken');
    const [, setPunchoutReturnURL] = useCookieValue('punchout_return_url');
    const [punchoutRedirectURL, setPunchoutRedirectURL] = useCookieValue('punchout_redirect_url');
    const [tokenExpTime, setTokenExpTime] = useCookieValue(STORAGE_CONFIG.COOKIES.TOKENEXPTIME);
    const [settingsCookie, setSettingsCookie] = useCookieValue('Settings');
    const [cc2acCookie] = useCookieValue('cc2ac');
    const [ccExpCookie] = useCookieValue('ccExp');
    const userTypeVal = useCheckUser();
    const didMount = useDidMount();
    let randomId = uuid4();
    const authorityType = useCheckAuthorityType();
    const isP2POrPunchout = [AUTHORITY_TYPE.P2P, AUTHORITY_TYPE.PUNCHOUT].includes(authorityType);

    const [expiryInterval, setExpiryInterval] = useState('');
    const [projectInfoCookies, setProjectInfoCookies] = useState({});
    if (window?.location?.href?.includes('sbmid')) {
        let searchParams = new URLSearchParams(window.location.search);
        sessionStorage.setItem('sbmid', searchParams.get('sbmid'));
    }

    if (!isValidString(sessionStorage.getItem('sbsid'))) {
        sessionStorage.setItem('sbsid', randomId);
    }

    const [cartId, setCartCookie] = useCookieValue('cif.cart');
    const queryParams = useQueryParams();
    const [revokeCustomerToken] = useMutation(MUTATION_REVOKE_TOKEN);
    const isEmailAvailable = useAwaitQuery(MUTATION_CHECK_IS_EMAIL_AVAILABLE);
    const getAccountFavoriteList = useAwaitQuery(GET_ACCOUNT_FAVORITE_LIST);
    const getJobsiteFavoriteList = useAwaitQuery(GET_JOBSITE_FAVORITE_LIST);
    const [getGuestSession] = useMutation(MUTATION_GUEST_SESSION);
    const [fetchAccountProjectsMutation] = useMutation(FETCH_ACCOUNTS_PROJECTS);
    const [createValidateTokenByRefreshMutation] = useMutation(VALIDATE_TOKEN_BY_REFRESH);
    const factory = props.reducerFactory || userAction;
    const initialState = props.initialState || userInitialState;
    const ccCookies = [
        'SLLat',
        'SLLong',
        'SLA',
        'SLC',
        'SLS',
        'SLZ',
        'CurrentJobSite',
        'locationPC',
        'projectName',
        'projectAddress2',
        'primaryContactName',
        'phoneNumber',
        'accessNotes',
        'poNumber'
    ];
    const clearUserStorage = () => {
        clearLocalStorage();
        sessionStorage.clear();
        if (getEnv() !== 'localhost') {
            deleteCookies(['cif.magentoToken', 'MY_SESSION_ASP']);
        } else {
            deleteCookies();
        }
    };

    const resetUser = () => {
        try {
            clearSentryScope();
            clearUserStorage();
        } catch (e) {
            clearUserStorage();
        }
    };

    const clearProjectDetailCookies = () => {
        let settingsKey = [];
        let settingData = (settingsCookie && settingsCookie.split('&')) || [];
        let settingCookieData = '';
        for (let i in settingData) {
            let cookieKey = settingData[i].split('=');
            if (ccCookies.indexOf(cookieKey[0]) === -1) {
                settingsKey.push(settingData[i]);
            }
        }
        settingsKey.push(`CurrentJobSite=${encodeURIComponent('')}`);
        settingsKey.push(`locationPC=${encodeURIComponent('')}`);
        settingsKey.push(`projectName=${encodeURIComponent('')}`);
        settingsKey.push(`projectAddress2=${encodeURIComponent('')}`);
        settingsKey.push(`primaryContactName=${encodeURIComponent('')}`);
        settingsKey.push(`phoneNumber=${encodeURIComponent('')}`);
        settingsKey.push(`accessNotes=${encodeURIComponent('')}`);
        settingsKey.push(`poNumber=${encodeURIComponent('')}`);
        settingsKey.push(`SLLat=${encodeURIComponent('')}`);
        settingsKey.push(`SLLong=${encodeURIComponent('')}`);
        settingsKey.push(`SLA=${encodeURIComponent('')}`);
        settingsKey.push(`SLC=${encodeURIComponent('')}`);
        settingsKey.push(`SLS=${encodeURIComponent('')}`);
        settingsKey.push(`SLZ=${encodeURIComponent('')}`);
        settingCookieData = settingsKey.join('&');
        setSettingsCookie(settingCookieData);
    };
    const getProjectsFromCookie = () => {
        const settingsCookie = cookieValue('Settings');
        const projectObj = {};
        const projectsInfo = settingsCookie?.split('&');
        for (let i in projectsInfo) {
            let cookiePair = projectsInfo[i].split('=');
            projectObj[cookiePair[0]] = decodeURIComponent(cookiePair[1]);
        }
        return projectObj;
    };
    const [userState, dispatch] = useReducer(factory(resetUser), initialState);

    const {
        userType,
        userProfile,
        isUserProfileLoading,
        isOverRidePCLoading,
        allAccountProjects,
        favoriteJobsiteListData,
        isProfileLoaded
    } = userState;

    const [generateCustomerTokenUsingOkta] = useMutation(MUTATION_GENERATE_TOKEN_MAGENTO);

    const [getBuyerAccount] = useMutation(GET_BUYER_ACCOUNT);

    const getMagentoAuthToken = async (newAccessToken, newRefreshToken, newMagentoToken = magentoTokenCookie) => {
        // earlier for older punchout we were setting punchout access and refresh token
        setAccessToken(newAccessToken || accessToken);
        setRefreshToken(newRefreshToken || refreshToken);

        // if it's a P2P user then we need to fetch buyer info
        if (authorityType === AUTHORITY_TYPE.P2P) {
            if (!newMagentoToken) {
                await fetchAndHandleBuyerAccountInfo(newAccessToken || accessToken);
                // clear start and end date
                localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE);
                localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE);
            }
        }

        if (punchoutCompanyId) {
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID, punchoutCompanyId);
        }
        localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.YOURDETAILS);
        var guestCartId = cartId;
        setCartCookie('', 0);
        try {
            const { data, error } = await generateCustomerTokenUsingOkta({
                variables: {
                    guestCartId: newMagentoToken || !guestCartId ? undefined : guestCartId,
                    accessToken: newAccessToken || accessToken,
                    idToken: guestSessionId ? guestSessionId : undefined,
                    refreshToken: newRefreshToken || refreshToken,
                    discardCustomerCart:
                        JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.DISCARD_OLD_CART)) || false
                },
                context: {
                    headers: { 'company-id': parseInt(localStorage.getItem('companyID')) || 1 }
                }
            });
            const userLogin = {
                accessToken: cookieValue(STORAGE_CONFIG.COOKIES.ACCESSTOKEN) || '',
                loginToken: data?.generateCustomerTokenUsingOkta?.logintoken
            };
            localStorage.setItem('user-login', JSON.stringify(userLogin));
            setLoginToken(data?.generateCustomerTokenUsingOkta?.logintoken);
            return { profileResponse: data, error: error };
        } catch (e) {
            if (e?.response?.status >= 500) {
                logError(e, true, 'getMagentoAuthToken', [newAccessToken, newRefreshToken]);
            } else {
                logError(e, false, 'getMagentoAuthToken', [newAccessToken, newRefreshToken]);
            }
            return { error: e?.response?.data?.error || e?.message || 'Something went wrong' };
        }
    };

    const getGuestSessionId = async () => {
        try {
            getGuestSession({
                fetchPolicy: 'network-only'
            }).then(data => {
                setGuestSessionId(data?.data?.customSession?.magento_session_id, 60 * 60, true);
                if (!isValidString(sbaidCookie)) {
                    setsbaidCookie(data?.data?.customSession?.magento_session_id, 3600 * 24 * 3650);
                }
                logSentryUserId(data?.data?.customSession);
                if (isValidString(data?.data?.customSession?.guest_id)) {
                    setGuestUserId(data?.data?.customSession?.guest_id?.substring(0, 25));
                } else {
                    setGuestUserId(uuid4()?.substring(0, 25));
                }
            });
        } catch (error) {
            logError(error, false, 'getGuestSessionId');
            dispatch({ type: 'error', error });
        }
    };

    const logoutUser = (source = '', sessionExpired = false, returnUrl = '') => {
        if (source) {
            logError(`unexpected logout happened - ${source}`);
        }
        resetUser();
        if (authorityType === AUTHORITY_TYPE.P2P) {
            handleSignout();
        } else {
            const redirectURL = redirectSignOutURL(sessionExpired, returnUrl);
            window.location.href = redirectURL;
        }
    };

    const setSignout = userType => {
        if (userType === 'cash') {
            document.querySelector('#signoutCash')?.addEventListener('click', () => {
                logoutUser();
            });
        } else {
            document.querySelector('#signoutCredit ')?.addEventListener('click', () => {
                logoutUser();
            });
        }
    };

    useEffect(() => {
        if (isPunchoutPage()) {
            // sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.PROFILERESPONSE);
        }

        //clear punchout session if page reloads on industries page for older punchout
        clearPunchoutSession();
        if (authorityType === AUTHORITY_TYPE.PUNCHOUT && isPunchoutPage()) {
            deleteCookie('Settings');
            setCartCookie('', 0);
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, '');
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, '');
        }

        //on page reload, test if cookies account is same as local storage account, else clear jobsite cookies,
        //this is to handle the scenario of account sync on cc pages
        if (userTypeVal === 'credit') {
            const cookieAccount = getProjectsFromCookie();
            const selectedAccID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTED_ACCOUNT_ID);
            if (cookieAccount?.CurrentWynneAccount !== selectedAccID) {
                if (isCCPage() || !selectedAccID) {
                    localStorage.setItem(
                        STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID,
                        cookieAccount?.CurrentWynneAccountCID || 1
                    );
                }
                localStorage.setItem(
                    STORAGE_CONFIG.LOCAL_STORAGE.SELECTED_ACCOUNT_ID,
                    cookieAccount?.CurrentWynneAccount
                );
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.RECOMMENDED_JOBSITE);
                clearProjectDetailCookies();
            }
        }
        //remove jobsites fetched check while loading
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.ISJOBSITESFETCHED);
    }, []);

    useEffect(() => {
        if (settingsCookie) {
            let newSettings = getProjectsFromCookie();
            if (!projectInfoCookies?.CurrentWynneAccount) {
                setProjectInfoCookies(newSettings);
            } else {
                let accountNumber = projectInfoCookies?.CurrentWynneAccount;
                if (accountNumber != newSettings?.CurrentWynneAccount) {
                    setProjectInfoCookies(newSettings);
                }
            }
        }
    }, [settingsCookie]);

    const checkIfTokenIsSet = () => {
        if (userType === null) {
            return false;
        } else if (userType === 2) {
            // guest user, userTYpe=! 2,
            return true;
        } else {
            return tokenExpTime;
        }
    };

    useEffect(() => {
        const isTokenSet = checkIfTokenIsSet();
        if (didMount && isTokenSet) {
            if (!isUserProfileLoading && !isOverRidePCLoading) {
                dispatch({ type: 'setIsProfileLoaded', value: true });
                const event = new CustomEvent('onProfileLoadComplete', { detail: userProfile });
                document?.dispatchEvent(event);
            }
        }
    }, [didMount, isUserProfileLoading, isOverRidePCLoading, userType, tokenExpTime]);

    const clearPunchoutSession = () => {
        if (authorityType === AUTHORITY_TYPE.PUNCHOUT && window.location == window.parent.location) {
            setPunchoutReturnURL('', 0);
            setPunchoutRedirectURL('', 0);
            setAccessToken('', 0);
            setRefreshToken('', 0);
            logoutUser();
        }
    };

    const updateUserProfileInContext = (profileData = {}, isTokenExpiring = false) => {
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.SHOWGUESTCHECKOUTLOGIN, false);
        if (isP2POrPunchout) {
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CIF_MAGENTOTOKEN, profileData?.customer_token);
        }
        setCustomerCart(profileData?.cart_token, CART_AGE.AUTHENTICATED_USER);
        setMagentoTokenCookie(profileData?.customer_token);
        formatUserProfileData(profileData, isTokenExpiring);
    };

    const getUserProfile = async (newAccessToken, newRefreshToken) => {
        try {
            let accessTokenCookie = cookieValue('accesstoken');
            let profileData = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE);
            if (!profileData) {
                const { profileResponse, error } = await getMagentoAuthToken(newAccessToken, newRefreshToken);
                if (error) {
                    logoutUser(`error from magento auth token api - ${error}`);
                }
                profileData = profileResponse?.generateCustomerTokenUsingOkta;
            } else {
                profileData = JSON.parse(profileData);
            }
            if (accessTokenCookie && profileData?.message === SUCCESS_MSG.SESSION_ACTIVE) {
                initSessionInterval();
                updateUserProfileInContext(profileData);
            } else {
                fetchUpdatedUserProfile();
            }
        } catch (e) {
            logError('getUserProfile line 694');
        }
    };

    useEffect(() => {
        const projectInfoCookies = getProjectsFromCookie();
        let isUserProfileCalled = false;
        if (queryParams.get('oktaAccessToken')) {
            setRefreshToken(queryParams.get('oktaRefreshToken'));
            setAccessToken(queryParams.get('oktaAccessToken'));
            window.location.href = window.location.origin + window.location.pathname + '?SuccessfulLogin=true';
        } else {
            if (refreshToken || isP2POrPunchout) {
                /* logout user when cc2ac and ccExp cookie is not present (no punchout and no local) */
                if (!(cc2acCookie && ccExpCookie) && getEnv() !== 'localhost' && !isP2POrPunchout) {
                    logoutUser('', true);
                } else {
                    if (projectInfoCookies?.CurrentWynneAccountCID) {
                        localStorage.setItem(
                            STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID,
                            projectInfoCookies?.CurrentWynneAccountCID || 1
                        );
                    }
                    getUserProfile(null, null);
                    isUserProfileCalled = true;
                }
            } else {
                if (!isValidSession()) {
                    resetUser();
                }
                dispatch({ type: 'setUserType', value: 2 }); // for guest user
                dispatch({ type: 'setIsUserProfileLoading', value: false });
            }
        }
        if (!magentoTokenCookie) {
            if (!guestSessionId) {
                getGuestSessionId();
            }
            setSentryScope();
        }
        if (!isUserProfileCalled) {
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.DISCARD_OLD_CART);
        }
    }, []);

    const handleLogoutForP2P = async () => {
        try {
            await signOut();
            await clearAemCookies();
            clearLocalStorage();
            sessionStorage.clear();
            deleteCookies();
            window.parent.location.href = punchoutRedirectURL;
        } catch (error) {
            logError(error, false, 'error in handleLogoutForP2P');
        }
    };

    const fetchAndHandleBuyerAccountInfo = async accessToken => {
        try {
            const { data } = await getBuyerAccount({
                variables: {
                    accessToken: accessToken
                }
            });

            if (data?.getBuyerAccount && data?.getBuyerAccount?.status === true) {
                setMagentoTokenCookie(data?.getBuyerAccount?.data?.magentoToken);
            }

            return data;
        } catch (error) {
            logError(error, false, 'error in fetchAndHandleBuyerAccountInfo');
        }
    };

    useEffect(() => {
        const signIn = document.querySelector('.sign__in');
        if (signIn) {
            const returnUrl =
                window.location.pathname.indexOf(config.pagePaths.wiresCrossedPage) > -1 ? '/' : window.location.href;
            if (!window.location.href.includes('returnUrl')) {
                if (window.location.pathname !== config.pagePaths.homePage) {
                    const returnUrlQueryString = `${signIn?.href}/?returnUrl=${returnUrl}`;
                    signIn.href = returnUrlQueryString;
                }
            }
        }
        if (userTypeVal) {
            setSignout(userTypeVal);
        }
    }, [magentoTokenCookie, userTypeVal]);

    const updateP2PUserProfile = async userProfile => {
        // This block is used to get P2P Favorite account list
        try {
            const { data, error } = await getAccountFavoriteList({
                fetchPolicy: 'network-only'
            });
            const response = data?.getCustomerFavoriteAccounts;
            const favArray = [];
            const resultArray = userProfile?.accounts?.map(obj => {
                const isFavorite = response?.includes(obj?.account?.toString());
                if (isFavorite) {
                    favArray.push({ ...obj, isFavorite });
                }
                return { ...obj, isFavorite };
            });
            dispatch({ type: SET_ACCOUNT_FAVORITE_LIST_DATA, payload: favArray });
            const updatedUserProfile = { ...userProfile, accounts: resultArray };
            dispatch({ type: 'setUserProfile', userProfile: updatedUserProfile });
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE, JSON.stringify(updatedUserProfile));
            dispatch({ type: 'setUserType', value: userProfile?.Type });
        } catch (error) {
            logError(error, false, 'getAccountFavoritesCall');
        }
    };

    const formatJobsites = projectData => {
        // This function is used to add isFavorite flag in acountProject objects
        const resultArray = projectData?.map(obj => {
            const isFavorite = favoriteJobsiteListData?.some(
                item => item?.rmJobsiteId?.trim() === obj?.rmJobsiteId?.trim()
            );
            return { ...obj, isFavorite };
        });

        return resultArray;
    };

    const formatJobsiteAccountProjects = projectData => {
        const formatResultArrays = formatJobsites(projectData);
        dispatch({ type: 'setAccountProjects', accountProjects: formatResultArrays });
        if (!allAccountProjects.length) {
            dispatch({ type: SET_ALL_ACCOUNT_PROJECTS, allAccountProjects: formatResultArrays });
        }
    };

    const formatUserProfileData = async (profileData, isTokenExpiring) => {
        try {
            let userProfile = {};
            const userTypeFromProfile = getUserType(profileData?.type);
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.USERTYPE, userTypeFromProfile);
            const projectInfoCookies = getProjectsFromCookie();
            const isUserProfileStored = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE);
            if (isUserProfileStored) {
                userProfile = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE) || '{}');
            } else {
                let skipQtyObj = {};
                const phoneNum = profileData?.telephone?.split('-');
                if (profileData?.cart?.items_count > 0) {
                    localStorage.setItem(
                        STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY,
                        profileData?.cart?.items_count
                    );
                }
                if (profileData?.skip_qty?.length > 0) {
                    profileData.skip_qty.forEach(item => (skipQtyObj[item?.sku] = { ...item }));
                }
                const {
                    firstname = '',
                    lastname = '',
                    driver_license_number = '',
                    driver_license_state = '',
                    address_latitude = '',
                    address_longitude = '',
                    swsguid = '',
                    line1 = '',
                    postal_code = '',
                    oktaUserId = 'abc',
                    ...rest
                } = profileData;
                userProfile = {
                    ...rest,
                    firstName: firstname,
                    lastName: lastname,
                    oktaUserId: oktaUserId,
                    accounts:
                        userTypeFromProfile === USER_TYPE.CREDIT ? getFormattedAccounts(profileData?.accounts) : [],
                    dlNumber: driver_license_number,
                    dlState: driver_license_state,
                    userGuid: swsguid,
                    isSelectedAccountIsNonCorpAccount: setIsCorpLinkAccount(),

                    phone:
                        phoneNum?.length !== 1
                            ? `(${phoneNum?.[0]}) ${phoneNum?.[1]}-${phoneNum?.[2]}`
                            : `(${profileData?.telephone?.substr(0, 3)}) ${profileData?.telephone?.substr(
                                3,
                                3
                            )}-${profileData?.telephone?.substr(6, 4)}`,
                    address1: line1,
                    zip: formatjobsiteZip(postal_code),
                    addressLatitude: address_latitude,
                    addressLongitude: address_longitude,
                    Type: userTypeFromProfile === USER_TYPE.CREDIT ? 0 : 1,
                    skip_qty: skipQtyObj
                };
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE, JSON.stringify(userProfile));
            }
            if (userTypeFromProfile === USER_TYPE.CREDIT && authorityType !== AUTHORITY_TYPE.P2P && !isTokenExpiring) {
                if (!profileData?.accounts?.length) {
                    dispatch({ type: 'setIsUserProfileLoading', value: false });
                }
            }
            /** This block is to avoid account and jobsite default selection for P2P */
            if (authorityType === AUTHORITY_TYPE.P2P) {
                userProfile['default_account'] = null;
                userProfile['default_jobsite'] = null;

                if (!projectInfoCookies?.CurrentWynneAccount) {
                    /** setIsProfileLoaded dispatch is not needed here as we are doing same in below useEffect */
                    // dispatch({ type: 'setIsProfileLoaded', value: true });
                    dispatch({ type: 'setIsOverRidePCLoading', value: false });
                    dispatch({ type: 'setIsUserProfileLoading', value: false });
                    dispatch({ type: SET_RATES_LOADING_FLAG, isRatesLoading: false });
                }
            }
            if (!localStorage.getItem('userType')) {
                setSignout(getUserType(profileData?.type));
            }
            if (
                getUserType(profileData?.type) === 'credit' &&
                authorityType !== AUTHORITY_TYPE.P2P &&
                !isTokenExpiring
            ) {
                if (!profileData?.accounts?.length) {
                    dispatch({ type: 'setIsUserProfileLoading', value: false });
                }
            }

            if (authorityType === AUTHORITY_TYPE.P2P) {
                // this block is only for P2P to format useProfile
                updateP2PUserProfile(userProfile);
            } else {
                dispatch({ type: 'setUserProfile', userProfile: userProfile });
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE, JSON.stringify(userProfile));
                dispatch({ type: 'setUserType', value: userProfile?.Type });
            }
            setSessionId(profileData?.magento_session_id);
            logSentryUserId(profileData);
            setSentryScope(profileData?.type, profileData?.cart_token);
            setsbuidCookie(profileData?.swsguid);
            if (getUserType(profileData?.type) === 'credit') {
                const companyId =
                    projectInfoCookies?.CurrentWynneAccountCID || userProfile?.accounts[0]?.companyId || 1;
                const accountNum = projectInfoCookies?.CurrentWynneAccount || userProfile?.accounts[0]?.account;
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID, companyId);
                if (!projectInfoCookies?.CurrentWynneAccount) {
                    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTED_ACCOUNT_ID, accountNum);
                }
            } else if (userTypeFromProfile === USER_TYPE.CASH) {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID, userProfile?.companyId);
            }
        } catch (er) {
            logError(er, false, 'formatUserProfileData');
            dispatch({ type: 'setIsUserProfileLoading', value: false });
            dispatch({ type: 'setUserType', value: userProfile?.Type || 2 });
        }
    };

    const setIsCorpLinkAccount = () => {
        const selectedAccID = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CORP_ACCOUNT_DETAILS));
        return !Boolean(selectedAccID?.isCorpLinkAccount);
    };

    const getFormattedAccounts = accounts => {
        const formattedAccounts = [];
        const totalAccounts = accounts?.length;

        accounts.forEach(account => {
            let ac = { ...account, account: account?.id, companyID: account?.companyId };
            if (ac?.isCorpLinkAccount || totalAccounts === 1) {
                // if multiple accounts are present then storing only account which has isCorpLinkAccount=true.
                // if only single account is there then storing the same account.
                const corpAccountDetails = { ...ac };
                localStorage.setItem(
                    STORAGE_CONFIG.LOCAL_STORAGE.CORP_ACCOUNT_DETAILS,
                    JSON.stringify(corpAccountDetails)
                );
            }
            formattedAccounts.push(ac);
        });

        return formattedAccounts;
    };

    const setToken = token => {
        setUserCookie(token);
        if (isP2POrPunchout) {
            sessionStorage.setItem('cif.userToken', token);
        }
        dispatch({ type: 'setToken', token });
    };

    const setError = error => {
        dispatch({ type: 'error', error: parseError(error) });
    };

    const setCustomerCart = (cartId, age) => {
        dispatch({ type: 'setCartId', cartId });
        setCartCookie(cartId, age);
    };

    const signOut = async () => {
        await signOutUserAction({ revokeCustomerToken, setCartCookie, setUserCookie, dispatch });
    };

    const resetCustomerCart = async fetchCustomerCartQuery => {
        await resetCustomerCartAction({ fetchCustomerCartQuery, dispatch });
    };

    const getAccountProjectsDotCom = async (
        accountId,
        search = '',
        invalidateCache = false,
        isprojectDetails = false
    ) => {
        let timeoutId = '';
        try {
            const params = getURLParams();
            if (params.createProjectSuccess && JSON.parse(params.createProjectSuccess)) {
                invalidateCache = true;
            }
            timeoutId = setTimeout(() => dispatch({ type: 'beginProjectsLoading' }), ENV_CONFIG.LOADER_DELAY);
            dispatch({ type: SET_IS_JOBSITE_LOADED, payload: timeoutId });
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.PROJECTLOADINGTIMERID, timeoutId);
            const { projectData, error } = await fetchAccountProjects({
                fetchAccountProjectsMutation,
                accountId,
                search,
                invalidateCache,
                isprojectDetails
            });
            if (error) {
                if (error?.response?.status >= 500) {
                    logError(error, true, 'getAccountProjectsDotCom', [accountId, invalidateCache]);
                } else {
                    logError(error, false, 'getAccountProjectsDotCom', [accountId, invalidateCache]);
                }

                dispatch({ type: 'setIsUserProfileLoading', value: false });
                dispatch({ type: 'error', error });
                clearTimeout(timeoutId);
                dispatch({ type: 'endProjectsLoading' });
                dispatch({ type: SET_IS_JOBSITE_LOADED, payload: '' });
                return { projectData: null, error };
            }
            if (!isprojectDetails) {
                if (authorityType === AUTHORITY_TYPE.P2P) {
                    //This function is used to add isFavorite flag in accountProjects based on favorite jonsites
                    formatJobsiteAccountProjects(projectData);
                } else {
                    dispatch({ type: 'setAccountProjects', accountProjects: projectData });
                }
            }
            clearTimeout(timeoutId);
            dispatch({ type: 'endProjectsLoading' });
            dispatch({ type: SET_IS_JOBSITE_LOADED, payload: '' });
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISJOBSITESFETCHED, true);
            return { projectData, error };
        } catch (e) {
            clearTimeout(timeoutId);
            dispatch({ type: 'endProjectsLoading' });
            dispatch({ type: SET_IS_JOBSITE_LOADED, payload: '' });
        } finally {
            clearTimeout(timeoutId);
            dispatch({ type: 'endProjectsLoading' });
            dispatch({ type: SET_IS_JOBSITE_LOADED, payload: '' });
        }
    };

    const addProject = async (projectDetails, companyID) => {
        const url = authorityType === AUTHORITY_TYPE.P2P ? P2P_JOBSITE_URL : PROJECT_SITE_NEW_URL;
        try {
            const { error } = await axiosInstance.post(url, projectDetails);
            if (error) {
                logError(error, false, 'addProject', [projectDetails]);
                dispatch({ type: 'error', error });
                return { projectData: null, error: error };
            } else {
                const { projectData } = await getAccountProjectsDotCom(projectDetails?.accountId, '', true);
                for (let i in projectData) {
                    if (projectData[i]?.jobName?.toLowerCase() === projectDetails?.projectName?.toLowerCase()) {
                        return { projectData: projectData[i], error: error };
                    }
                }
                return { projectData: projectData[0], error: error };
            }
        } catch (error) {
            logError(error, false, 'addProject', [projectDetails]);
            dispatch({ type: 'error', error });
            return {
                projectData: null,
                error: error?.response?.data?.error,
                addressInfo: error?.response?.data?.addressInfo
            };
        }
    };

    const fetchUpdatedUserProfile = async isTokenExpiring => {
        /* this will get updated user profile with updated tokens */
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE);
        const tokenResponse = await validateAndGenerateToken(true);
        if (tokenResponse?.access_token && tokenResponse?.refresh_token) {
            const { profileResponse } = await getMagentoAuthToken(
                tokenResponse?.access_token,
                tokenResponse?.refresh_token,
                tokenResponse?.magento_token
            );
            if (profileResponse?.generateCustomerTokenUsingOkta?.message === SUCCESS_MSG.SESSION_ACTIVE) {
                updateUserProfileInContext(profileResponse?.generateCustomerTokenUsingOkta, isTokenExpiring);
            } else {
                logoutUser('getUserProfile line 990');
            }
        } else {
            logoutUser('getUserProfile line 993');
        }
    };

    const initSessionInterval = () => {
        if (!tokenExpTime) {
            validateAndGenerateToken(true);
        } else {
            const currentTime = Math.floor(Date.now() / 1000);
            const remSessionActivetime = tokenExpTime - parseInt(currentTime);
            if (remSessionActivetime > 120) {
                //keeping buffer
                setExpiryInterval(
                    setTimeout(() => {
                        fetchUpdatedUserProfile(true);
                    }, remSessionActivetime * 1000)
                );
            } else {
                fetchUpdatedUserProfile();
            }
        }
    };

    const validateAndGenerateToken = async (shouldRegenerateToken = false) => {
        try {
            if (!accessToken || shouldRegenerateToken) {
                const { data = {}, error } = await validateRefreshTokenMutation({
                    createValidateTokenByRefreshMutation,
                    dispatch
                });
                if (error || !data?.access_token) {
                    logoutUser();
                } else if (data && isValidString(data?.status) && data?.message !== ERROR_MSG.CANCELLED_REQUEST) {
                    setAccessToken(data?.access_token, data?.expires_in); //120 sec is buffer to handle keep alive call delay
                    setTokenExpTime(Math.floor(Date.now() / 1000) + data?.expires_in, data?.expires_in);
                    setRefreshToken(data?.refresh_token, CART_AGE.AUTHENTICATED_USER);
                    setMagentoTokenCookie(data?.magento_token);
                    updateTokensOnCC(data?.access_token, data?.refresh_token);
                    const userLogin = {
                        accessToken: data?.access_token,
                        loginToken: JSON.parse(localStorage.getItem('user-login'))?.logintoken
                    };
                    localStorage.setItem('user-login', JSON.stringify(userLogin));
                    clearTimeout(expiryInterval);
                    if (data?.expires_in > 180) {
                        setExpiryInterval(
                            setTimeout(() => {
                                fetchUpdatedUserProfile(true);
                            }, (data?.expires_in - 120) * 1000)
                        );
                    } else {
                        logWarning(`Problem with session expiry time, ${data?.expires_in}`);
                        fetchUpdatedUserProfile();
                    }
                    return data;
                } else {
                    return data;
                }
            } else {
                const data = {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                    message: SUCCESS_MSG.SESSION_ACTIVE
                };
                return data;
            }
        } catch (e) {
            if (e?.response?.status >= 500) {
                logError(e?.response?.status + 'catch error', true, 'validateAndGenerateToken');
            } else {
                logError(e?.response?.status + 'catch error', false, 'validateAndGenerateToken');
            }
        }
    };

    const checkIsEmailAvailable = async emailId => {
        try {
            const { data, error } = await isEmailAvailable({
                variables: { email: emailId },
                fetchPolicy: 'network-only'
            });
            return { newUser: data?.isEmailAvailable?.is_email_available, error };
        } catch (error) {
            logError(error, false, 'checkIsEmailAvailable', [emailId]);
            dispatch({ type: 'error', error });
            return { newUser: null, error };
        }
    };

    const getFreqRentals = async (accountNumber, isCorpLinkAccount) => {
        try {
            const { data, error } = await axiosInstance.get(`${CUSTOMERS_API_URL}/${accountNumber}/frequentrentals`, {
                params: {
                    isCorpLinkAccount
                }
            });
            return { data: data?.data?.resultSet, error };
        } catch (error) {
            logError(error, false, 'getFreqRentals', [accountNumber, isCorpLinkAccount]);
            dispatch({ type: 'error', error });
            return { data: null, error };
        }
    };

    const isValidSession = () => {
        if (userTypeVal != USER_TYPE.GUEST) {
            if (!(cc2acCookie && ccExpCookie) && getEnv() !== 'localhost' && !isP2POrPunchout) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    window.getIsProfileLoaded = () => {
        return { isProfileLoaded, userProfile };
    };

    const { children } = props;
    const contextValue = [
        userState,
        {
            dispatch,
            setToken,
            setError,
            signOut,
            setCustomerCart,
            resetCustomerCart,
            addProject,
            getAccountProjectsDotCom,
            checkIsEmailAvailable,
            getFreqRentals,
            logoutUser,
            validateAndGenerateToken,
            setExpiryInterval,
            getProjectsFromCookie,
            expiryInterval,
            resetUser,
            isValidSession,
            handleLogoutForP2P
        }
    ];
    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

UserContextProvider.propTypes = {
    reducerFactory: func,
    initialState: object,
    children: node
};
export default UserContextProvider;

export const useUserContext = () => useContext(UserContext);
