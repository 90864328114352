import React, { useContext, useEffect, useState } from 'react';
import { array, bool, func, string, object, oneOfType } from 'prop-types';
import Button from '../../../../atoms/button/button';
import { useIntl } from 'react-intl';
import { useFilterState } from '../../../../../cap';
import { useCartState } from '../../../../../../contexts/cart';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import useMedia from '../../../../../../hooks/useMedia';
import { MEDIA_TYPE } from '../../../../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../../../../constants/storageConfig';
import { VARIABLE_CONFIG } from '../../../../../../constants/analyticsConstants/Variables';
import { SET_RECOMPUTE_ITEM_AVAILABILITY } from '../../../../../../aem-core-components/actions/constants';
import { SET_IS_CAP_DETAILS_UPDATING, SET_SELECTED_STORE_DETAILS, STORE_VARIANT } from '../../../../../cap/constants';
import HideIcon from '../../../../../../resources/images/hide.svg';
import ShowEyeIcon from '../../../../../../resources/images/showeye.svg';
import CloseIcon from '../../../../../../resources/images/close.svg';
import ChevronLeft from '../../../../../../resources/images/chevron-left-white.svg';
import '../../../../../cap/pickupStore/pickupStore.scss';
import { checkoutDatalocator } from '../../../../../checkoutv2/checkoutAndOrderSummary/dataLocators';
import { ChooseAStoreViewWrapper } from '../../../ChooseAStoreView/ChooseAStoreViewWrapper';
import useCheckLocationEmpty from '../../../../../../hooks/useCheckLocationEmpty';
import { logError } from '../../../../utils/logger';
import { useCapUtils } from '../../../../../cap/hooks/useCapUtils';
import { LoadingIndicator } from '../../../../../../aem-core-components';
import { RENTAL_CHANGE_CONFIRMATION_MODAL } from '../../../../../../aem-core-components/context/Modal/constants';
import { ModalContext } from '../../../../../../aem-core-components/context/ModalContext';

const ChooseStoreOverlay = ({
    isOpen,
    availableStores,
    closeStoreModal,
    startingDate,
    showAvailableStartDate,
    showInDrawerForTablet,
    showDateModal,
    showMonochromaticMap,
    showAlertModal,
    isDateModalOpen,
    showShimmer
}) => {
    const [{ selectedStoreDetails, startDate }, dispatch] = useFilterState();
    const [, cartDispatch] = useCartState();
    const useModalState = () => useContext(ModalContext);
    const { openModal } = useModalState();
    const [{ sendConfirmationModalAnalytics, sendConfirmationModalViewAnalytics }] = useAnalytics();
    const { fetchLocationCoordinates } = useCheckLocationEmpty();
    const { localLat, localLong } = fetchLocationCoordinates();
    const [onStoreDetailsClick, setOnStoreDetailsClick] = useState(false);
    const [mapToggle, setMapToggle] = useState(true);
    const [selectedStoreValue, setSelectedStoreValue] = useState('');
    const [storeDetailData, setStoreDetailData] = useState({});
    const [isStoreSelected, setIsStoreSelected] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const mediaType = useMedia();
    const intl = useIntl();
    const { getCartUpdatedDetails } = useCapUtils();
    const [nextAvailableStartDate, setNextAvailableStartDate] = useState(startDate || new Date());
    const isTabletView = mediaType === MEDIA_TYPE.TABLET;

    useEffect(() => {
        setSelectedStoreValue(selectedStoreDetails?.pc || '');
        setStoreDetailData(selectedStoreDetails || {});
    }, [selectedStoreDetails?.pc]);

    useEffect(() => {
        if (startDate && !showAvailableStartDate) {
            setNextAvailableStartDate(startDate);
        } else if (startingDate && showAvailableStartDate) {
            setNextAvailableStartDate(startingDate);
        }
    }, [startDate, startingDate, showAvailableStartDate]);

    const handleMapToggle = () => {
        setMapToggle(!mapToggle);
    };

    const handleCloseClick = () => {
        setIsStoreSelected(false);
        closeStoreModal();
        setOnStoreDetailsClick(false);
        setStoreDetailData(selectedStoreDetails);
        setSelectedStoreValue(selectedStoreDetails?.pc || '');
    };

    const onStoreDetailClick = item => {
        setStoreDetailData(item);
        setOnStoreDetailsClick(true);
        setMapToggle(true);
    };

    const sendEvents = () => {
        setOnStoreDetailsClick(false);
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT_STORE,
                `${storeDetailData?.pc || availableStores?.data[0]?.pc} ${VARIABLE_CONFIG.EVENT_LABEL.BACK}`
            );
        } catch (error) {
            logError(error, false, 'sendEvents');
        }
    };

    const handleBackClick = () => {
        if (onStoreDetailsClick) {
            sendEvents();
        } else {
            handleCloseClick();
        }
    };

    const saveStoreDetailsInContext = async choosenStore => {
        saveStoreDetails(choosenStore);
        /* To save details in local storgae when cahneg store and date CTA is there and update the details only when date is selected*/
        if (isDateModalOpen) {
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.LOCALSELECTEDSTOREDETAILS, JSON.stringify(choosenStore));
        } else {
            dispatch({ type: SET_SELECTED_STORE_DETAILS, selectedStoreDetails: choosenStore, localLat, localLong });
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS);
            cartDispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
        }
        setOnStoreDetailsClick(false);

        showDateModal(true);
    };

    const onRentalChangeConfirmation = async (
        isAccepted,
        choosenStore,
        cartDetails,
        handleUpdateStoreDetails,
        isCloseClicked,
        isBackClicked
    ) => {
        /* To remove the details unavailable items from the cart if user agree to proceed with updated rental details*/
        if (isAccepted) {
            dispatch({ type: SET_IS_CAP_DETAILS_UPDATING, isCapDetailsUpdating: true });
            //commenting it as we will have t display unavailable items in cart
            //await removeUnavailableItemsFromCart(cartDetails);
            /* To update the store detials in context*/
            handleUpdateStoreDetails(choosenStore);
            dispatch({ type: SET_IS_CAP_DETAILS_UPDATING, isCapDetailsUpdating: false });
        }
        sendConfirmationModalAnalytics(isAccepted, isCloseClicked, isBackClicked, cartDetails);
    };

    const handleRentalChangeModal = async (choosenStore, saveStoreDetailsInContext) => {
        closeStoreModal();
        setShowLoader(true);
        /* Fetch the changed details from the compare estimates graphQL*/
        const { cartDetails } = await getCartUpdatedDetails({
            selectedPickupStore: choosenStore,
            isSourceCallRequired: false
        });
        setShowLoader(false);
        if (cartDetails?.length) {
            openModal(RENTAL_CHANGE_CONFIRMATION_MODAL, {
                isOpen: true,
                productList: cartDetails,
                handleConfirmation: (isAccepted, isCloseClicked, isBackClicked) =>
                    onRentalChangeConfirmation(
                        isAccepted,
                        choosenStore,
                        cartDetails,
                        saveStoreDetailsInContext,
                        isCloseClicked,
                        isBackClicked
                    )
            });
            sendConfirmationModalViewAnalytics(cartDetails);
        } else {
            closeStoreModal();
            saveStoreDetailsInContext(choosenStore);
        }
    };

    const handleChooseStoreFromStoreDetails = async store => {
        let choosenStore = store || storeDetailData;
        if (showAlertModal) {
            handleRentalChangeModal(choosenStore, saveStoreDetailsInContext);
            return;
        }
        closeStoreModal();
        saveStoreDetailsInContext(choosenStore);
    };

    const saveStoreDetails = details => {
        setIsStoreSelected(true);
        details?.operatingHours?.sort((day1, day2) => day1?.dayOfWeek - day2?.dayOfWeek);
        setStoreDetailData(details);
        setSelectedStoreValue(details?.pc);
    };

    const handleChooseThisStoreClick = async () => {
        if (showAlertModal) {
            handleRentalChangeModal(storeDetailData, saveChooseThisStoreDetailsInContext);
            return;
        }
        closeStoreModal();
        saveChooseThisStoreDetailsInContext(storeDetailData);
    };

    const saveChooseThisStoreDetailsInContext = async storeDetailData => {
        setOnStoreDetailsClick(false);
        /* To save details in local storgae when cahneg store and date CTA is there and update the details only when date is selected*/
        if (isDateModalOpen) {
            localStorage.setItem(
                STORAGE_CONFIG.LOCAL_STORAGE.LOCALSELECTEDSTOREDETAILS,
                JSON.stringify(storeDetailData)
            );
        } else {
            dispatch({ type: SET_SELECTED_STORE_DETAILS, selectedStoreDetails: storeDetailData, localLat, localLong });
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS);
            cartDispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
        }
        if (isStoreSelected) {
            showDateModal(true);
        }
    };

    const mobileCloseButton = () => {
        return (
            <div className="mobile-view-footer mobile-view-footer-white">
                <Button
                    type="button"
                    className="button button-primary button-block"
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:choose-this-store' })}
                    onClick={handleChooseThisStoreClick}
                    data-testid={checkoutDatalocator.mobile_close_button}>
                    {intl.formatMessage({ id: 'checkout:choose-this-store' })}
                </Button>
            </div>
        );
    };

    const pickupStoreDrawerHeading = () => {
        return (
            <>
                <Button data-testid="back-button" className="button" onClick={handleBackClick}>
                    <ChevronLeft />
                    Back
                </Button>
                {!onStoreDetailsClick && !isTabletView && (
                    <button
                        data-testid="map-button"
                        className={`${'mapButton'} button`}
                        onClick={handleMapToggle}
                        tabIndex={'0'}>
                        {mapToggle ? (
                            <>
                                <HideIcon className="buttton_icon" aria-hidden={true} tabIndex={'-1'} /> Hide map{' '}
                            </>
                        ) : (
                            <>
                                <ShowEyeIcon className="buttton_icon" aria-hidden={true} tabIndex={'-1'} /> Show map{' '}
                            </>
                        )}
                    </button>
                )}
                <Button data-testid="close-icon" className="button close-icon">
                    <CloseIcon onClick={handleCloseClick} />
                </Button>
            </>
        );
    };
    const pickupStoreDrawerFooter = () => {
        return (
            <Button
                type="button"
                className="button button-primary button-block"
                onClick={handleChooseThisStoreClick}
                buttonAriaLabel={intl.formatMessage({ id: 'checkout:choose-this-store' })}
                data-testid={checkoutDatalocator.handleChooseThisStoreClick}>
                {intl.formatMessage({ id: 'checkout:choose-this-store' })}
            </Button>
        );
    };

    return (
        <>
            {isOpen && (
                <ChooseAStoreViewWrapper
                    makeSelectedClicked={isOpen}
                    mobileCloseButton={mobileCloseButton}
                    onStoreDetailsClick={onStoreDetailsClick}
                    mapToggle={mapToggle}
                    storesData={availableStores}
                    selectedStoreValue={selectedStoreValue}
                    setSelectedStoreValue={setSelectedStoreValue}
                    storeDetailData={storeDetailData}
                    mediaType={mediaType}
                    handleChooseThisStoreClick={handleChooseThisStoreClick}
                    onStoreDetailClick={onStoreDetailClick}
                    saveStoreDetails={saveStoreDetails}
                    setOnStoreDetailsClick={setOnStoreDetailsClick}
                    handleChooseStoreFromStoreDetails={handleChooseStoreFromStoreDetails}
                    handleMapToggle={handleMapToggle}
                    handleCloseClick={handleCloseClick}
                    nextAvailableStartDate={nextAvailableStartDate}
                    isBestMatch={false}
                    currentLocation={{ lat: localLat, long: localLong }}
                    pickupStoreFooterClass={'pickupStore_footer'}
                    pickupStoreHeaderClass={'pickupStore_header'}
                    mapMobileClass={'map-mobile__content'}
                    variant={STORE_VARIANT.CAP_HEADER}
                    pickupStoreDrawerHeading={pickupStoreDrawerHeading}
                    pickupStoreDrawerFooter={pickupStoreDrawerFooter}
                    showAvailableStartDate={showAvailableStartDate}
                    showInDrawerForTablet={showInDrawerForTablet}
                    showMonochromaticMap={showMonochromaticMap}
                    storeDetailsClassName="pickupStore__store-details"
                    storesClassName="pickupStore__stores"
                    showShimmer={showShimmer}
                />
            )}
            {showLoader && (
                <div className="cap-date-overlay">
                    <LoadingIndicator global />
                </div>
            )}
        </>
    );
};
export default React.memo(ChooseStoreOverlay);

ChooseStoreOverlay.defaultProps = {
    isOpen: false,
    availableStores: [],
    closeStoreModal: () => {},
    startingDate: '',
    showAvailableStartDate: false,
    showInDrawerForTablet: false,
    showDateModal: () => {},
    showMonochromaticMap: false,
    showAlertModal: false,
    isDateModalOpen: false,
    showShimmer: false
};

ChooseStoreOverlay.propTypes = {
    isOpen: bool,
    availableStores: oneOfType([array, object]),
    closeStoreModal: func,
    startingDate: string,
    showAvailableStartDate: bool,
    showInDrawerForTablet: bool,
    showDateModal: func,
    showMonochromaticMap: bool,
    showAlertModal: bool,
    isDateModalOpen: bool,
    showShimmer: bool
};
