import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useFilterState } from '../../cap';
import { useCartState } from '../../../contexts/cart/cartContext';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useCapUtils } from '../../cap/hooks/useCapUtils';
import { logError } from '../../global/utils/logger';
import useComputeLocation from '../../../hooks/useComputeLocation';
import useCartEstimate from './useCartEstimate';
import { useDidMount } from '../../../hooks/useDidMount';
import { getCartDetails } from '../../../aem-core-components/actions/cart';
import { useAtp } from '../../cap/hooks/useATP';
import { useAwaitQuery, useCookieValue } from '../../../aem-core-components/utils/hooks';
import useUserData from '../../../hooks/useUserData';
import { useMutation } from '@apollo/client';
import MUTATION_CREATE_CART from '../../../aem-core-components/queries/mutation_create_guest_cart.graphql';
import MUTATION_ADD_TO_CART from '../../../aem-core-components/queries/mutation_add_to_cart.graphql';
import MUTATION_ADD_VIRTUAL_TO_CART from '../../../aem-core-components/queries/mutation_add_virtual_to_cart.graphql';
import MUTATION_ADD_SIMPLE_AND_VIRTUAL_TO_CART from '../../../aem-core-components/queries/mutation_add_simple_and_virtual_to_cart.graphql';
import MUTATION_ADD_BUNDLE_TO_CART from '../../../aem-core-components/queries/mutation_add_bundle_to_cart.graphql';
import CART_DETAILS_QUERY from '../../../aem-core-components/queries/query_cart_details.graphql';
import { mktoLeadCreation } from '../../../../api/mktoLeadCreation';
import { getProductList } from '../../../aem-core-components/actions/product/actions';
import useMinicart from './useMinicart';
import useAnalytics from '../../../hooks/useAnalytics';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { usePricing } from '../../../hooks/usePricing';
import QUERY_PRODUCT_LIST_STATIC from '../../../aem-core-components/queries/query_product_list_static';
import useMarketo from '../../../hooks/useMarketo';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { usePageType } from '../../../hooks/usePageType';
import { CART_PAGE, HOME_PAGE } from '../../../constants/screenConstants';
import { getProductAssetsList, isCheckoutPage, isGlobalRates } from '../../global/utils/commonUtils';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { SET_STATIC_TILE_PRODUCT_DETAILS } from '../../../aem-core-components/actions/constants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import config from '../config';
import { SET_END_DATE, SET_START_DATE } from '../../cap/constants';

export const useGlobal = () => {
    const [
        { isItemPCAdding, isCombinedTabLoading, selectedCombinedTabData, isLoading, isRatesLoading, cidPcList },
        cartDispatch
    ] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }, filterDispatch] = useFilterState();
    const [{ isProfileLoaded }, { isValidSession }] = useUserContext();
    const { fetchandUpdatePricingPCs } = useComputeLocation();
    const { updateBsrPricingPCs } = useCapUtils();
    const { isRentalDetailsAvailable } = useCheckLocationEmpty();
    const didMount = useDidMount();
    const { handleATPCart } = useAtp();
    const pageType = usePageType();
    const [{ getEstimates }] = useCartEstimate();
    const [{ updateFourHourRentals }] = useUserData();
    const [createCartMutation] = useMutation(MUTATION_CREATE_CART);
    const [addToCartMutation] = useMutation(MUTATION_ADD_TO_CART);
    const [addVirtualItemMutation] = useMutation(MUTATION_ADD_VIRTUAL_TO_CART);
    const [addSimpleAndVirtualItemMutation] = useMutation(MUTATION_ADD_SIMPLE_AND_VIRTUAL_TO_CART);
    const [addBundleItemMutation] = useMutation(MUTATION_ADD_BUNDLE_TO_CART);
    const [addProduct, setAddProduct] = useCookieValue('addProduct');
    const [{ payloadEcommercePageData, payloadEcommerceViewItemList }] = useAnalytics();
    const { sendEventsForEcommercePageData, sendEventsForViewItemList } = useAnalyticsContext();
    const { getProductPrices } = usePricing();
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const staticProductsListQuery = useAwaitQuery(QUERY_PRODUCT_LIST_STATIC);
    const [seasonalProducts, setSeasonalProducts] = useState([]);
    const [popularProducts, setPopularProducts] = useState([]);
    const [{ getCartDetailsForMarketo }] = useMarketo();
    const [{ cart, cartId }, { addItem, dispatch }] = useMinicart({
        queries: {
            createCartMutation,
            addToCartMutation,
            cartDetailsQuery,
            addVirtualItemMutation,
            addBundleItemMutation,
            addSimpleAndVirtualItemMutation
        }
    });

    // useEventListener(document, 'aem.cif.add-to-cart', addItem);

    useEffect(() => {
        if (
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) &&
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE)
        ) {
            if (
                moment().isAfter(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE), 'day') ||
                moment().isAfter(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE), 'day')
            ) {
                localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE);
                localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE);
            }
        }
        if (!startDate || !endDate) {
            filterDispatch({
                type: SET_START_DATE,
                startDate: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) || ''
            });
            filterDispatch({
                type: SET_END_DATE,
                endDate: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE) || ''
            });
        }
        checkIfStartDateElapsed();
    }, []);

    useEffect(() => {
        let isCombinedTabs = true;
        if (!ENV_CONFIG.COMBINED_TABS) {
            isCombinedTabs = false;
        }
        if (
            (!isCombinedTabLoading || !isCombinedTabs) &&
            isGlobalRates() &&
            pageType?.toLowerCase() === HOME_PAGE.toLowerCase()
        ) {
            let assetsOnHomePage = getProductAssetsList() || [];
            let combinedSelectedTabAssets = selectedCombinedTabData || [];
            let totalItems = [...new Set([...assetsOnHomePage, ...combinedSelectedTabAssets])];
            sendEventsForEcommercePageData(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATA,
                payloadEcommercePageData([], totalItems?.length)
            );
        }
    }, [isCombinedTabLoading]);

    useEffect(() => {
        /* Fetch rates for the items on Equipement and Rental Page */
        if (
            isProfileLoaded &&
            (pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE ||
                pageType === VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE) &&
            isRentalDetailsAvailable()
        ) {
            if (cidPcList?.length > 0) {
                callRatesForStaticItems();
            }
        }
    }, [isProfileLoaded, cidPcList]);

    useEffect(() => {
        /* Call the locations API whenever the locations changes */
        const updatePricingPCs = async () => {
            if (projectDetails?.selectedProjectLatititude || viewCart?.lat) {
                await fetchandUpdatePricingPCs();
            }
        };

        if (isProfileLoaded) {
            updatePricingPCs();
        }
    }, [isProfileLoaded, projectDetails?.selectedProjectLatititude, viewCart?.lat]);

    useEffect(() => {
        /* Call the BSR API for the elivery flow */
        if (pageType !== CART_PAGE && !isCheckoutPage()) {
            updateBsrPricingPCs();
        }
    }, [startDate, projectDetails?.selectedProjectLatititude, viewCart?.lat, viewCart?.isInStorePickup]);

    useEffect(() => {
        /* Call the cartId call when product is added for CC pages */
        async function getCartDetailsFunction() {
            if (addProduct) {
                await getCartDetails({
                    cartDetailsQuery,
                    cartId,
                    dispatch,
                    filterDispatch,
                    handleATPCart,
                    isRentalDetailsAvailable: isRentalDetailsAvailable()
                });
            }
        }
        getCartDetailsFunction();
    }, [addProduct]);

    useEffect(() => {
        /* call estimates for added product */
        const callEstimates = async () => {
            try {
                if (didMount && isProfileLoaded) {
                    if (cart?.total_quantity > 0) {
                        //default value is undefined on page load therefore explicit false check, dont change to !isItemPCAdding
                        if (isItemPCAdding == false) {
                            //to handle addtocart in AEM
                            updateFourHourRentals(cart?.items[0]?.product?.sku);
                            getEstimates();
                        } else if (addProduct) {
                            setAddProduct('', 0);
                        }
                    }
                }
            } catch (error) {
                logError(error, false, 'getEstimates');
            }
        };
        callEstimates();
    }, [cart?.total_quantity, isItemPCAdding]);

    const checkIfStartDateElapsed = () => {
        if (!isNaN(new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getTime())) {
            if (
                new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getTime() <
                new Date().setHours(0, 0, 0, 0)
            ) {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, '');
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, '');

                filterDispatch({ type: SET_START_DATE, startDate: '' });
                filterDispatch({ type: SET_END_DATE, endDate: '' });
                return true;
            } else {
                return false;
            }
        } else return false;
    };

    const updateSeasonalAndPopularList = (productDetails, seasonalList, popularList) => {
        try {
            productDetails?.forEach(detail => {
                const seasonalMatchIndex = seasonalList?.findIndex(product => product?.catclass === detail?.catclass);
                const popularMatchIndex = popularList?.findIndex(product => product?.catclass === detail?.catclass);

                if (seasonalMatchIndex !== -1) {
                    seasonalList[seasonalMatchIndex].ec_pc_inventory = detail?.ec_pc_inventory;
                    seasonalList[seasonalMatchIndex].pc_availability = detail?.pc_availability;
                } else if (popularMatchIndex !== -1) {
                    popularList[popularMatchIndex].ec_pc_inventory = detail?.ec_pc_inventory;
                    popularList[popularMatchIndex].pc_availability = detail?.pc_availability;
                }
            });
        } catch (error) {
            logError(error, false, 'updateSeasonalAndPopularList');
        }
    };
    const callRatesForStaticItems = async () => {
        try {
            let aemProducts = document.querySelectorAll(config.mountingPoints.addToCartTile);
            let products = [...aemProducts];
            var skuList = [];
            let seasonalList = [];
            let popularList = [];
            const midpoint = Math.ceil(products?.length / 2);
            for (let i = 0; i < products?.length; i++) {
                if (products[i]?.dataset?.productdata) {
                    var productData = JSON.parse(products[i]?.dataset?.productdata);
                    skuList.push(productData.catclass);
                    const containerAncestor = products[i]?.closest('.container');
                    if (containerAncestor) {
                        const firstChild = containerAncestor?.children[0]?.firstElementChild;
                        if (firstChild?.textContent?.trim() === VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME) {
                            productData.listName = VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME;
                            popularList.push(productData);
                        } else {
                            productData.listName = VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME;
                            seasonalList.push(productData);
                        }
                    }
                }
            }
            if (skuList?.length) {
                const skuListFirstHalf = skuList?.slice(0, midpoint);
                const skuListSecondHalf = skuList?.slice(midpoint);
                const [firstResponse, secondResponse] = await Promise.allSettled([
                    getProductList({
                        productListQuery: staticProductsListQuery,
                        skuList: skuListFirstHalf,
                        pageSize: skuListFirstHalf?.length,
                        handleATPCart
                    }),
                    getProductList({
                        productListQuery: staticProductsListQuery,
                        skuList: skuListSecondHalf,
                        pageSize: skuListSecondHalf?.length,
                        handleATPCart
                    })
                ]);
                const productDetails = firstResponse?.value?.productDetails.concat(
                    secondResponse?.value?.productDetails
                );
                cartDispatch({ type: SET_STATIC_TILE_PRODUCT_DETAILS, staticTileProductDetails: productDetails });
                if (productDetails?.length > 0) {
                    getProductPrices(productDetails, pageType);
                    productDetails?.forEach(detail => {
                        const seasonalMatchIndex = seasonalList?.findIndex(
                            product => product?.catclass === detail?.catclass
                        );
                        const popularMatchIndex = popularList?.findIndex(
                            product => product?.catclass === detail?.catclass
                        );
                        if (seasonalMatchIndex !== -1) {
                            seasonalList[seasonalMatchIndex].ec_pc_inventory = detail?.ec_pc_inventory;
                            seasonalList[seasonalMatchIndex].pc_availability = detail?.pc_availability;
                        } else if (popularMatchIndex !== -1) {
                            popularList[popularMatchIndex].ec_pc_inventory = detail?.ec_pc_inventory;
                            popularList[popularMatchIndex].pc_availability = detail?.pc_availability;
                        }
                    });
                    updateSeasonalAndPopularList(productDetails, seasonalList, popularList);
                    if (popularList?.length > 0) {
                        setPopularProducts(popularList);
                    }
                    if (seasonalList?.length > 0) {
                        setSeasonalProducts(seasonalList);
                    }
                }
            }
        } catch (error) {
            logError(error, false, 'callRatesForStaticItems');
        }
    };

    useEffect(() => {
        const isCartUpdatedWithPrice = JSON.parse(
            sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CART_UPDATED_WITH_PRICE) || false
        );
        if (cart?.id && !isLoading && isCartUpdatedWithPrice) {
            if (cart?.items?.length > 0) {
                mktoLeadCreation(getCartDetailsForMarketo());
            }
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CART_UPDATED_WITH_PRICE);
        }
    }, [isLoading, cart?.id]);

    useEffect(() => {
        if (pageType === VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE && !isRatesLoading) {
            sendEventsForViewItemList(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_VIEW_ITEM_LIST,
                payloadEcommerceViewItemList(popularProducts)
            );
            sendEventsForViewItemList(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_VIEW_ITEM_LIST,
                payloadEcommerceViewItemList(seasonalProducts)
            );
        }
    }, [pageType, isRatesLoading]);
};
