import { STORAGE_CONFIG } from '../../constants/storageConfig';
import {
    EDIT_PROJECT_INFO,
    POP_CART_STACK,
    RESET_ACCESSORIES_AND_ADDONS,
    RESET_CONSUMABLES,
    RESET_HTGO,
    RESET_PAYMENT,
    RESET_PROJECT_DETAILS,
    RESET_YOUR_DETAILS,
    SET_ANIMATED_HEADER,
    SET_AVS_RESULT,
    SET_CART_OVERLAY,
    SET_CONSUMABLES,
    SET_CONSUMABLES_SELECTED_ITEMS,
    SET_CREDIT_NEWADDR_FLAG,
    SET_CURRENT_OFFSET_VALUE,
    SET_END_DATE_SLOTS,
    SET_GUEST_USER_DETAILS,
    SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
    SET_MODAL,
    SET_OPTIONAL_PLANS_CHECKED,
    SET_SELECTED_RENTAL_EQUIPMENT,
    SET_SHOW_RENTAL_EQUIPMENT_SCREEN,
    SET_START_DATE_SLOTS,
    UPDATE_ACCOUNT,
    UPDATE_CONSUMABLES,
    SET_CURRENT_ACCESSORIES_AVAILABLE_QUANTITY,
    SET_CURRENT_ADDONS_AVAILABLE_QUANTITY,
    SET_SAVED_AVAILABLE_QUANTITY,
    SET_SUBMIT_RESERVATION,
    RESET_HTGO_FROM_YOUR_DETAILS,
    SET_PAYMENT,
    SET_PAYMENT_SESSION,
    SET_SELECTED_PAYMENT_METHOD,
    SET_TIMEZONE_ID,
    SET_HOME_ASSETS_RATES,
    SHOW_GUEST_CHECKOUT_LOGIN,
    UPDATE_PUNCHOUT_USER_DETAILS,
    SET_YOUR_DETAILS,
    SET_CART_CONTEXT_FIELDS,
    CREDIT_CUSTOM_ACCOUNT_INFO,
    SET_PUNCHOUT_USER_DATA,
    SET_OPTIONAL_PLANS_CHECKBOX_VISIBLE,
    SET_RATES_LOADING_FLAG,
    SET_OPTIONAL_PLANS_IS_EDIT,
    SET_PAYMENT_TOKEN_DATA,
    SET_CLICKED_MAKE_CHANGES,
    SET_SOURCES_LOADING_FLAG,
    SET_CART_ITEMS,
    SET_CASH_PROFILE_UPDATED,
    RESET_EDIT_ON_CHECKOUT_COMPLETE,
    SET_ORDER_ESTIMATES_FINAL_TOTAL,
    SET_SHOW_DELINQUENT_ACCOUNT_MODAL,
    SET_IS_RESERVATION_CLICKED,
    SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
    SET_SELECTED_COMBINED_TAB_DATA,
    SET_IS_COMBINED_TAB_LOADING,
    SET_START_CLICK,
    SET_END_CLICK,
    SET_CALENDAR_DATE_INTERACTION,
    RESET_CLICKS,
    RESET_ORDER_ESTIMATES,
    HTGO_EMPTY_DELIVERY_PICKUP_TIME,
    SET_ESTIMATES_IN_CART,
    SET_CID_PC_LIST,
    SET_IS_CART_LOADING,
    SET_IS_COMBINED_TABS_LOADING,
    SHOW_ACCOUNT_OVERLAY,
    RESET_PRICES,
    SET_STATIC_TILE_PRODUCT_DETAILS,
    SET_IS_BULK_ATC_MODAL_OPEN,
    SET_SELECTED_TRANSMIT_OR_SAVE_FOR_LATER,
    SET_ORDER_SUMMARY_DETAILS,
    SET_UPDATED_EQUIPMENT,
    SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
    SET_IS_ITEM_PC_ADDED,
    SET_ATP_PC_LIST,
    SET_CHECKOUT_ERROR_DETAILS,
    SET_RECOMPUTE_ITEM_AVAILABILITY,
    SET_RETURN_TIME_SLOTS,
    SET_PICKUP_TIME_SLOTS,
    SET_SHOW_AVS_ERROR_IN_CAP
} from '../../aem-core-components/actions/constants';
import { getProjectsFromCookie } from '../../components/global/utils/commonUtils';
import { isValidString } from '../../components/global/utils/logger';
export const cartAction = (
    setCartCookie,
    setAccountNameCookie,
    setAccountCookie,
    setAcCIDCookie,
    setSettingsCookieFunc
) => {
    return (state, action) => {
        switch (action.type) {
            case 'close':
                return {
                    ...state,
                    isOpen: false
                };
            case 'open':
                return {
                    ...state,
                    isOpen: true
                };
            case 'beginLoading':
                return {
                    ...state,
                    isLoading: true
                };
            case 'endLoading':
                return {
                    ...state,
                    isLoading: false
                };
            case 'beginEditing':
                return {
                    ...state,
                    isEditing: true,
                    editItem: action.item
                };
            case 'endEditing':
                return {
                    ...state,
                    isEditing: false,
                    editItem: {}
                };
            case 'cartId':
                return {
                    ...state,
                    cartId: action.cartId
                };
            case 'reset':
                setCartCookie('', 0);
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE);
                return {
                    ...state,
                    cartId: null,
                    errorMessage: null,
                    cart: null
                };
            case 'cart': {
                return {
                    ...state,
                    cart: action.cart,
                    isLoading: false
                };
            }
            case 'error':
                return {
                    ...state,
                    errorMessage: action.error
                };
            case 'discardError':
                return {
                    ...state,
                    errorMessage: null
                };
            case SET_IS_CART_LOADING:
                return {
                    ...state,
                    isCartLoading: action.isCartLoading
                };
            case SET_IS_COMBINED_TABS_LOADING:
                return {
                    ...state,
                    isCombinedTabsLoading: action.isCombinedTabsLoading
                };
            case SET_CART_OVERLAY:
                return {
                    ...state,
                    cartOverlay: action.cartOverlay,
                    cartStack: [...state.cartStack, action.cartOverlay]
                };
            case POP_CART_STACK:
                let cartStackBuf = [...state.cartStack];
                cartStackBuf.pop();
                return {
                    ...state,
                    cartOverlay: action.cartOverlay,
                    cartStack: cartStackBuf
                };
            case SET_CART_CONTEXT_FIELDS:
                return action.cartContextData;

            case SET_PAYMENT_TOKEN_DATA:
                return {
                    ...state,
                    paymentTokenData: action.value
                };
            case SET_CLICKED_MAKE_CHANGES:
                return {
                    ...state,
                    clickedMakeChanges: action.value
                };

            case SET_HOW_TO_GET_YOUR_ORDER_FIELDS:
                return {
                    ...state,
                    howToGetYourOrderDetails: {
                        ...state.howToGetYourOrderDetails,
                        [action.key]: action.value
                    }
                };
            case HTGO_EMPTY_DELIVERY_PICKUP_TIME:
                const emptySlots = {
                    slot: '',
                    label: ''
                };
                return {
                    ...state,
                    howToGetYourOrderDetails: {
                        ...state.howToGetYourOrderDetails,
                        selectedDeliveryTime: emptySlots,
                        selectedPickUpTime: emptySlots
                    }
                };
            case SET_GUEST_USER_DETAILS:
                return {
                    ...state,
                    guestUserDetails: {
                        ...state.guestUserDetails,
                        [action.key]: action.value
                    }
                };
            case SHOW_GUEST_CHECKOUT_LOGIN:
                return {
                    ...state,
                    showGuestCheckoutLogin: action.value
                };
            case RESET_ORDER_ESTIMATES:
                return {
                    ...state,
                    orderEstimates: {
                        totals: {
                            rentalAmount: '',
                            fuelCharges: '',
                            deliveryPickUpCharges: '',
                            rppCharges: '',
                            allOtherCharges: '',
                            finalTotal: '',
                            salesAmount: '',
                            salesTax: ''
                        }
                    },
                    cart: {
                        ...state.cart,
                        items: state?.cart?.items.map(item => {
                            return {
                                ...item,
                                prices: {}
                            };
                        }),
                        prices: {},
                        estimatesResponse: {}
                    }
                };
            case UPDATE_ACCOUNT:
                setSettingsCookieFunc(action, null);
                const projectInfoCookies = getProjectsFromCookie();
                return {
                    ...state,
                    userAccount: {
                        accountName: action.accountName,
                        accountNumber: action.accountNumber,
                        accountStatus: action.accountStatus,
                        isCorpLinkAccount:
                            typeof action?.isCorpLinkAccount != 'undefined'
                                ? action?.isCorpLinkAccount
                                : isValidString(projectInfoCookies?.IsCorpLink),
                        isValueUpdated: action?.isValueUpdated || false
                    }
                };
            case SET_ANIMATED_HEADER:
                return {
                    ...state,
                    animateHeader: action.animateHeader
                };

            case SET_MODAL:
                return {
                    ...state,
                    modal: action.modal
                };
            case SET_CREDIT_NEWADDR_FLAG:
                localStorage.setItem('isCreditNewAddress', action.isCreditNewAddress);
                return {
                    ...state,
                    isCreditNewAddress: action.isCreditNewAddress
                };
            case SET_AVS_RESULT:
                return {
                    ...state,
                    avsResponse: action.avsResponse
                };
            case SET_START_DATE_SLOTS:
                return {
                    ...state,
                    startDateSlots: action.windowsResponse
                };
            case SET_END_DATE_SLOTS:
                return {
                    ...state,
                    endDateSlots: action.windowsResponse
                };
            case SET_CONSUMABLES:
                return {
                    ...state,
                    consumables: {
                        ...state.consumables,
                        data: action.consumables,
                        availableQuantity: action.availableQuantity,
                        savedAvailableQuantity: action.availableQuantity
                    }
                };
            case SET_SAVED_AVAILABLE_QUANTITY:
                return {
                    ...state,
                    consumables: {
                        ...state.consumables,
                        [action.key]: action.value
                    }
                };
            case RESET_CONSUMABLES:
                return {
                    ...state,
                    consumables: action.consumables
                };
            case SET_CONSUMABLES_SELECTED_ITEMS:
                return {
                    ...state,
                    consumables: {
                        ...state.consumables,
                        selected: action.selectedItems
                    }
                };
            case UPDATE_CONSUMABLES:
                return {
                    ...state,
                    consumables: {
                        ...state.consumables,
                        [action.key]: action.value
                    }
                };
            case SET_SHOW_RENTAL_EQUIPMENT_SCREEN:
                return {
                    ...state,
                    consumables: {
                        ...state.consumables,
                        showRentalEquipmentScreen: action.flag
                    }
                };
            case SET_SELECTED_RENTAL_EQUIPMENT:
                return {
                    ...state,
                    consumables: {
                        ...state.consumables,
                        selectedRentalEquipment: action.selectedRentalEquipment
                    }
                };
            case SET_OPTIONAL_PLANS_CHECKED: {
                return {
                    ...state,
                    optionalPlan: {
                        ...state.optionalPlan,
                        isRPPChecked: action.isRPPChecked,
                        isFuelChargeChecked: action.isFuelChargeChecked
                    }
                };
            }
            case RESET_YOUR_DETAILS:
                return {
                    ...state,
                    guestUserDetails: {
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        email: '',
                        marketoFeed: false
                    }
                };
            case RESET_HTGO_FROM_YOUR_DETAILS:
                return {
                    ...state,
                    howToGetYourOrderDetails: {
                        ...state.howToGetYourOrderDetails,
                        selectedPickupTime: '',
                        selectedReturnTime: '',
                        selectedDeliveryTime: {
                            slot: '',
                            label: ''
                        },
                        selectedPickUpTime: {
                            slot: '',
                            label: ''
                        },
                        // selectedStoreDetails: {}, ToDo revert this change when working on retain data on edit
                        isBestMatch: true
                    }
                };
            case RESET_HTGO:
                return {
                    ...state,
                    howToGetYourOrderDetails: {
                        ...state.howToGetYourOrderDetails,
                        selectedPickupTime: '',
                        selectedReturnTime: '',
                        bestMatchStoresData: { data: [] },
                        closestStoresData: { data: [] },
                        selectedDeliveryTime: {
                            slot: '',
                            label: ''
                        },
                        selectedPickUpTime: {
                            slot: '',
                            label: ''
                        },
                        selectedStoreDetails: {},
                        isBestMatch: true,
                        storesAPIStatus: 500
                    }
                };
            case RESET_ACCESSORIES_AND_ADDONS:
                return {
                    ...state,
                    consumables: {
                        data: {},
                        selected: {},
                        selectedRentalEquipment: {},
                        showRentalEquipmentScreen: false,
                        isRentalEquipmentsAdded: false,
                        saveAndContinue: false,
                        orderSummaryRentals: {},
                        selectedRentalQtyAndPrice: {
                            totalRentalQty: 0,
                            totalAddonPrice: 0,
                            totalAddonQty: 0,
                            totalRentalPrice: 0
                        }
                    }
                };
            case RESET_PAYMENT:
                return {
                    ...state,
                    paymentData: {
                        selectedPaymentMethod: 0,
                        selectedPaymentDetails: {}
                    }
                };
            case SET_PAYMENT:
                return {
                    ...state,
                    paymentData: {
                        ...state.paymentData,
                        [action.key]: action.value
                    }
                };
            case SET_SELECTED_PAYMENT_METHOD:
                return {
                    ...state,
                    paymentData: {
                        ...state.paymentData,
                        [action.key]: action.value
                    }
                };
            case SET_PAYMENT_SESSION:
                return {
                    ...state,
                    sessionAPIResponse: action.sessionAPIResponse
                };
            case RESET_PROJECT_DETAILS:
                return {
                    ...state,
                    projectInfo: {
                        primaryContactName: '',
                        phoneNumber: '',
                        accessNotes: '',
                        poNumber: ''
                    }
                };
            case EDIT_PROJECT_INFO:
                return {
                    ...state,
                    projectInfo: {
                        primaryContactName: action.primaryContactName,
                        phoneNumber: action.phoneNumber,
                        accessNotes: action.accessNotes,
                        poNumber: action.poNumber
                    }
                };
            case SET_SUBMIT_RESERVATION:
                return {
                    ...state,
                    submitReservation: {
                        ...state.submitReservation,
                        [action.key]: action.value
                    }
                };
            case SET_CURRENT_OFFSET_VALUE:
                return {
                    ...state,
                    currentOffset: action.currentOffset
                };
            case SET_CURRENT_ACCESSORIES_AVAILABLE_QUANTITY: {
                return {
                    ...state,
                    consumables: {
                        ...state.consumables,
                        availableQuantity: {
                            ...state.consumables.availableQuantity,
                            remainingAccessories: {
                                ...state.consumables.availableQuantity.remainingAccessories,
                                [action.key]: {
                                    ...state.consumables.availableQuantity.remainingAccessories[action.key],
                                    accessoriesRemainingQuantity: action.value
                                }
                            }
                        }
                    }
                };
            }
            case SET_CURRENT_ADDONS_AVAILABLE_QUANTITY: {
                return {
                    ...state,
                    consumables: {
                        ...state.consumables,
                        availableQuantity: {
                            ...state.consumables.availableQuantity,
                            remainingAddOns: {
                                ...state.consumables.availableQuantity.remainingAddOns,
                                [action.key]: {
                                    ...state.consumables.availableQuantity.remainingAddOns[action.key],
                                    addOnsRemainingQuantity: action.value
                                }
                            }
                        }
                    }
                };
            }
            case SET_TIMEZONE_ID:
                return {
                    ...state,
                    timeZoneID: action.timeZoneID
                };
            case SET_HOME_ASSETS_RATES:
                return {
                    ...state,
                    homeAssetsRates: action.homeAssetsRates
                };
            case CREDIT_CUSTOM_ACCOUNT_INFO:
                return {
                    ...state,
                    customBillingFields: action.customBillingFields
                };
            case SET_YOUR_DETAILS:
                return {
                    ...state,
                    guestUserDetails: action.guestUserDetails
                };
            case SET_PUNCHOUT_USER_DATA:
                return {
                    ...state,
                    punchoutUserData: action.punchoutUserData
                };
            case UPDATE_PUNCHOUT_USER_DETAILS:
                return {
                    ...state,
                    punchoutUserData: {
                        ...state.punchoutUserData,
                        [action.key]: action.value
                    }
                };

            case SET_OPTIONAL_PLANS_CHECKBOX_VISIBLE: {
                return {
                    ...state,
                    optionalPlan: {
                        ...state.optionalPlan,
                        isRPPCheckboxVisible: action.isRPPCheckboxVisible,
                        isFuelCheckboxVisible: action.isFuelCheckboxVisible
                    }
                };
            }
            case SET_RATES_LOADING_FLAG:
                return {
                    ...state,
                    isRatesLoading: action.isRatesLoading
                };
            case SET_OPTIONAL_PLANS_IS_EDIT: {
                return {
                    ...state,
                    optionalPlan: {
                        ...state.optionalPlan,
                        isEdit: action.isEdit
                    }
                };
            }
            case SET_SOURCES_LOADING_FLAG: {
                return {
                    ...state,
                    isSourcesLoading: action.isSourcesLoading
                };
            }
            case SET_SHOW_DELINQUENT_ACCOUNT_MODAL: {
                return {
                    ...state,
                    showDelinquentModal: action.showDelinquentModal
                };
            }
            case SET_START_CLICK: {
                return {
                    ...state,
                    startDateClick: true,
                    endDateClick: false,
                    calendarDateInteraction: false
                };
            }
            case SET_END_CLICK: {
                return {
                    ...state,
                    startDateClick: false,
                    endDateClick: true,
                    calendarDateInteraction: false
                };
            }
            case SET_CALENDAR_DATE_INTERACTION: {
                return {
                    ...state,
                    calendarDateInteraction: true
                };
            }
            case RESET_CLICKS: {
                return {
                    ...state,
                    startDateClick: false,
                    endDateClick: false,
                    calendarDateInteraction: false
                };
            }
            case SET_CART_ITEMS: {
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        id: action.id,
                        items: action.items,
                        total_quantity: action.total_quantity,
                        quantity_without_estimates: action?.quantity_without_estimates || false
                    }
                };
            }
            case SET_CART_AVAILABLE_UNVAILABLE_ITEMS: {
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        availableCartItems: action.availableCartItems,
                        unavailableCartItems: action.unavailableCartItems
                    }
                };
            }
            case SET_CASH_PROFILE_UPDATED: {
                return {
                    ...state,
                    cashProfileLatLongUpdated: action.cashProfileLatLongUpdated
                };
            }
            case RESET_EDIT_ON_CHECKOUT_COMPLETE: {
                return {
                    ...state,
                    isCheckoutEditResetComplete: action.isCheckoutEditResetComplete
                };
            }
            case SET_ORDER_ESTIMATES_FINAL_TOTAL: {
                return {
                    ...state,
                    orderEstimatesFinalTotal: action.orderEstimatesFinalTotal
                };
            }
            case SET_IS_RESERVATION_CLICKED: {
                return {
                    ...state,
                    isReservationClicked: action.value
                };
            }
            case SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED: {
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_ESTIMATE_ON_CART_OPEN_REQUIRED, action.value);
                return {
                    ...state,
                    isEstimateOnCartOpenRequired: action.value
                };
            }
            case SET_SELECTED_COMBINED_TAB_DATA: {
                return {
                    ...state,
                    selectedCombinedTabData: action.value
                };
            }
            case SET_IS_COMBINED_TAB_LOADING: {
                return {
                    ...state,
                    isCombinedTabLoading: action.value
                };
            }
            case SET_ESTIMATES_IN_CART: {
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        estimatesResponse: {
                            ...state.cart.estimatesResponse,
                            estimate: action.estimate
                        }
                    }
                };
            }
            case SET_CID_PC_LIST: {
                return {
                    ...state,
                    cidPcList: action.cidPcList
                };
            }
            case SET_ATP_PC_LIST: {
                return {
                    ...state,
                    atpPcList: action.atpPcList
                };
            }
            case SHOW_ACCOUNT_OVERLAY: {
                return {
                    ...state,
                    showAccountOverlay: action.showAccountOverlay
                };
            }
            case RESET_PRICES: {
                const resetItems = state?.cart?.items?.map(item => ({
                    ...item,
                    prices: {
                        ...item?.prices,
                        price: { ...item?.prices?.price, value: 0 },
                        row_total: { ...item?.prices?.row_total, value: 0 }
                    }
                }));
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        items: resetItems
                    }
                };
            }
            case SET_STATIC_TILE_PRODUCT_DETAILS: {
                return {
                    ...state,
                    staticTileProductDetails: action.staticTileProductDetails
                };
            }
            case SET_IS_BULK_ATC_MODAL_OPEN: {
                return {
                    ...state,
                    isBulkAddToCartModalOpen: action.isBulkAddToCartModalOpen
                };
            }
            case SET_SELECTED_TRANSMIT_OR_SAVE_FOR_LATER: {
                return {
                    ...state,
                    selectedTransmitOrSave: action.value
                };
            }
            case SET_ORDER_SUMMARY_DETAILS: {
                return {
                    ...state,
                    orderSummaryDetails: action.value
                };
            }
            case SET_UPDATED_EQUIPMENT: {
                return {
                    ...state,
                    editQuote: {
                        isUpdateAvailable: action.isUpdateAvailable,
                        updatedEquipmentData: action.payload
                    }
                };
            }
            case SET_IS_ITEM_PC_ADDED: {
                return {
                    ...state,
                    isItemPCAdding: action?.isItemPCAdding
                };
            }
            case SET_ORDER_SUMMARY_DETAILS: {
                return {
                    ...state,
                    orderSummaryDetails: action.value
                };
            }
            case SET_CHECKOUT_ERROR_DETAILS: {
                return {
                    ...state,
                    error: {
                        ...state.error,
                        avsError: action?.avsError || '',
                        timeZoneError: action?.timeZoneError || '',
                        storesError: action?.storesError || '',
                        startDateError: action?.startDateError || '',
                        endDateError: action?.endDateError || '',
                        timeSlotsError: action?.timeSlotsError || ''
                    }
                };
            }
            case SET_SHOW_AVS_ERROR_IN_CAP: {
                return {
                    ...state,
                    showAvsErrorInCap: action?.showAvsErrorInCap || false
                };
            }
            case SET_RECOMPUTE_ITEM_AVAILABILITY: {
                return {
                    ...state,
                    recomputeItemsAvailability: action.recomputeItemsAvailability
                };
            }
            case SET_PICKUP_TIME_SLOTS: {
                return {
                    ...state,
                    pickUpTimeSlots: action.pickUpTimeSlots
                };
            }
            case SET_RETURN_TIME_SLOTS: {
                return {
                    ...state,
                    returnTimeSlots: action.returnTimeSlots
                };
            }
            default:
                return state;
        }
    };
};
