import moment from 'moment';
import query_cart_detailsGraphql from '../../../aem-core-components/queries/query_cart_details.graphql';
import QUERY_PRODUCT_LIST from '../../../aem-core-components/queries/query_product_list';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import { usePricing } from '../../../hooks/usePricing';
import { useFilterState } from '../../cap';
import { useAtp } from '../../cap/hooks/useATP';
import { getCartDetails } from '../../../aem-core-components/actions/cart';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useCartState } from '../../../contexts/cart/cartContext';
import { useAwaitQuery } from '../../../aem-core-components/utils/hooks';
import { getInventoryPcs } from '../../cap/utils/atputils';
import { convertDateToMomentDateTime, formatjobsiteZip, isCheckoutPage } from '../../global/utils/commonUtils';
import { getDistance } from '../../cart/utils/helper';
import { isValidString, logError } from '../../global/utils/logger';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import {
    RESET_GEOGRAPHY,
    RESET_ORDER_ESTIMATES,
    SET_IS_CART_LOADING,
    SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED
} from '../../../aem-core-components/actions/constants';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { TILE_STATES } from '../../cap/constants';
import { AUTHORITY_TYPE } from '../../global/constants';

const useCartEstimate = () => {
    const userType = useCheckUser();
    const [{ cart, cartId, howToGetYourOrderDetails, userAccount, consumables, isOpen, homeAssetsRates }, dispatch] =
        useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }, filterDispatch] = useFilterState();
    const [{ userProfile, isUserProfileLoading }, { getProjectsFromCookie }] = useUserContext();
    const cartDetailsQuery = useAwaitQuery(query_cart_detailsGraphql);
    const { pc, location, lat, long, jobSiteAddr2, jobSiteCity, jobSiteState, jobSiteZip, isInStorePickup } = viewCart;
    const productListQuery = useAwaitQuery(QUERY_PRODUCT_LIST);
    const { findNearestPc, getOverrideRatesPc } = usePricing();
    const { handleATPCart } = useAtp();
    const { isSelectedLocationEmpty } = useCheckLocationEmpty();
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;

    const isSourcePCCallCriteria = (updatedItems = cart?.items) => {
        let sourcePCObj = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ)
            ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ))
            : {};
        let callPC = false;
        /* logic for triggering the PC call when dates or quantity changes */
        if (updatedItems?.length > 0 && startDate && endDate) {
            callPC = true;
        } else if (
            /* logic for trigerring the PC call on page load if sourcePC is not there in sessionStorage so as to avoid source call on page load */
            !isUserProfileLoading &&
            startDate &&
            endDate &&
            isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0 &&
            !parseInt(sourcePCObj?.pc)
        ) {
            callPC = true;
        }
        return callPC;
    };

    const isEstimatesWithOwnedPc = (updatedItems = cart?.items) => {
        if (isSourcePCCallCriteria(updatedItems) || getOverrideRatesPc()) {
            return false;
        } else {
            return true;
        }
    };

    const getEquipmentData = async (updatedItems = []) => {
        let items = updatedItems?.length > 0 ? updatedItems : cart?.items;
        let equipments = [];
        const nearbyPc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST));

        items?.forEach(item => {
            const { sku = '' } = item?.product;
            const quantity = item?.quantity;
            const catId = ('000' + sku.slice(0, 3)).slice(-3);
            const classId = ('0000' + sku.slice(3, 7)).slice(-4);
            let tileStatus = handleATPCart({
                catsku: sku,
                inventoryDataObj: item?.product?.ec_pc_inventory
            });
            if (isEstimatesWithOwnedPc(items)) {
                equipments.push({
                    catId,
                    classId,
                    quantity,
                    ownedPc: findNearestPc(getInventoryPcs(item?.product?.ec_pc_inventory), nearbyPc),
                    isItemAvailable: tileStatus === TILE_STATES.AVAILABLE || tileStatus === TILE_STATES.AVAILABLE_WITH_WARNING
                });
            } else {
                equipments.push({
                    catId,
                    classId,
                    quantity,
                    isItemAvailable: tileStatus === TILE_STATES.AVAILABLE || tileStatus === TILE_STATES.AVAILABLE_WITH_WARNING
                });
            }
        });

        let rentables = consumables?.selected;
        for (let i in rentables) {
            for (let j in rentables[i].rentals) {
                equipments.push({
                    catId: ('000' + j.slice(0, 3)).slice(-3),
                    classId: ('0000' + j.slice(3, 7)).slice(-4),
                    quantity: rentables[i].rentals[j].qty,
                    isItemAvailable: true // will always be true for accessories
                });
            }
        }

        return equipments;
    };

    const getSalesEquipmentData = () => {
        const itemObj = consumables?.selected;
        let newArray = [];
        Object.keys(itemObj).forEach(item => {
            let selectedArray = Object.keys(itemObj[item]);
            if (selectedArray.includes('addons')) {
                let purchaseArray = Object.values(itemObj[item]['addons']);
                purchaseArray.forEach(itemAddon => {
                    newArray.push({
                        itemNumber: itemAddon?.itemObj?.itemNumber,
                        quantity: itemAddon?.qty,
                        unitPrice: itemAddon?.price,
                        stockClass: itemAddon?.itemObj?.stockClass
                    });
                });
            }
        });
        return newArray;
    };

    const getStartDate = (isFromHTGO = false) => {
        let pickupTime = '';
        // TODO: @Nikhil split the code for CAP and checkout.
        if (!isInStorePickup) {
            let startDateTimeLocale;
            let startDateLocale = startDate;
            let startTimeLocale = '';
            if (howToGetYourOrderDetails.selectedDeliveryTime?.label) {
                startDateTimeLocale = howToGetYourOrderDetails.selectedDeliveryTime.slot?.split(' - ')[1];
                startTimeLocale = startDateTimeLocale.split('T')[1];
            } else if (howToGetYourOrderDetails.selectedPickUpTime?.label) {
                //if there is no delivery time, take time from pickup time for estimates call, date remains the same
                startDateTimeLocale = howToGetYourOrderDetails.selectedPickUpTime.slot?.split(' - ')[0]; // taking start time = pickup time, but date is the start date
                startTimeLocale = startDateTimeLocale.split('T')[1];
            }
            return convertDateToMomentDateTime(startDateLocale, startTimeLocale);
        } else {
            if (howToGetYourOrderDetails.selectedPickupTime) {
                pickupTime = moment(howToGetYourOrderDetails.selectedPickupTime, 'h:mm A');
            } else if (howToGetYourOrderDetails.selectedReturnTime) {
                pickupTime = moment(howToGetYourOrderDetails.selectedReturnTime, 'h:mm A');
            }
            return convertDateToMomentDateTime(
                isFromHTGO ? howToGetYourOrderDetails.selectedStartDate : startDate,
                pickupTime
            );
        }
    };

    const getEndDate = (isFromHTGO = false, localEndDate = '') => {
        let returnTime = '';
        // TODO: @Nikhil split the code for CAP and checkout.
        if (!isInStorePickup) {
            let endDateTimeLocale;
            let endDateLocale = endDate;
            let endTimeLocale = '';
            if (howToGetYourOrderDetails.selectedPickUpTime?.label) {
                endDateTimeLocale = howToGetYourOrderDetails.selectedPickUpTime.slot?.split(' - ')[0];
                endTimeLocale = endDateTimeLocale.split('T')[1];
            } else if (howToGetYourOrderDetails.selectedDeliveryTime?.label) {
                //if there is no pickup time, take time from delivery time for estimates call, date remains the same
                endDateTimeLocale = howToGetYourOrderDetails.selectedDeliveryTime.slot?.split(' - ')[1]; // taking end time = delivery time, but date is the end date
                endTimeLocale = endDateTimeLocale.split('T')[1];
            }
            return convertDateToMomentDateTime(endDateLocale, endTimeLocale);
        } else {
            if (howToGetYourOrderDetails.selectedReturnTime) {
                returnTime = moment(howToGetYourOrderDetails.selectedReturnTime, 'h:mm A');
            } else if (howToGetYourOrderDetails.selectedPickupTime) {
                returnTime = moment(howToGetYourOrderDetails.selectedPickupTime, 'h:mm A');
            }
            return convertDateToMomentDateTime(
                isFromHTGO ? howToGetYourOrderDetails.selectedEndDate : endDate,
                returnTime
            );
        }
    };

    const getTimeType = () => {
        const deliveryPickup = 'delivery-pickup';
        const delivery = 'Delivery';
        const pickup = 'Pickup';

        if (isCheckoutPage()) {
            // checkout page
            const currentStep = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CHECKOUT_CURRENT_STEP);
            if (currentStep && currentStep == 1) {
                return deliveryPickup;
            } else {
                if (viewCart?.isInStorePickup) {
                    return pickup;
                }
                return delivery;
            }
        } else {
            // minicart
            if (viewCart?.isInStorePickup) {
                return pickup;
            }
            return delivery;
        }
    };

    const getEstimates = async (
        localLocation,
        localPc = 0,
        localPCLat = '',
        localPCLong = '',
        isFromHTGO = false,
        updatedItems = []
    ) => {
        try {
            const projectCookies = getProjectsFromCookie();
            const isCartUpdatedWithPrice = sessionStorage.getItem(
                STORAGE_CONFIG.SESSION_STORAGE.IS_CART_UPDATED_WITH_PRICE
            );
            let payload = {};
            let browsePCObj = null;
            let cartEstimates = null;
            let commonPayload = {
                cartId,
                distance: getDistance(viewCart?.isInStorePickup),
                timeType: getTimeType(),
                lastResort: false,
                fulfillment: 1,
                dlSt: userType === USER_TYPE.CASH ? userProfile?.dlState : '',
                dlNumber: userType === USER_TYPE.CASH ? userProfile?.dlNumber : '',
                account:
                    userType === USER_TYPE.CREDIT
                        ? userAccount?.accountNumber || projectCookies?.CurrentWynneAccount
                        : 0,
                isDeliveryEstimate: !startDate && !endDate ? true : false
            };

            if (startDate && endDate) {
                const startDateTime = getStartDate(isFromHTGO);
                const endDateTime = getEndDate(isFromHTGO);
                if (startDateTime == endDateTime) {
                    dispatch({ type: 'endLoading' });
                    dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
                    dispatch({
                        type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                        key: 'isEstimateOnCartOpenRequired',
                        value: false
                    });
                    return;
                }
            }
            if (
                startDate &&
                endDate &&
                (isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0 ||
                    cart?.total_quantity > 0)
            ) {
                browsePCObj = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ)
                    ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ))
                    : {};
            } else {
                browsePCObj = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ)
                    ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ))
                    : {};
            }

            if (!localLocation) {
                const viewCartData =
                    (localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART) &&
                        JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART))) ||
                    {};
                const isCreditNewAddressFromStorage = localStorage.getItem(
                    STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS
                )
                    ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS))
                    : false;
                if (userType === USER_TYPE.CREDIT && !isCreditNewAddressFromStorage) {
                    if (!projectDetails?.projectAddress1) {
                        filterDispatch({
                            type: RESET_GEOGRAPHY
                        });
                        dispatch({
                            type: RESET_ORDER_ESTIMATES
                        });

                        if (!isP2PUser) {
                            dispatch({ type: 'endLoading' });
                            dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
                            dispatch({
                                type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                                key: 'isEstimateOnCartOpenRequired',
                                value: false
                            });
                            return;
                        }
                    }
                    let overridePC = JSON.parse(
                        sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}'
                    );
                    const pcLatVal =
                        String(overridePC?.latitude || '') ||
                        String(localPCLat || '') ||
                        String(browsePCObj?.lat || '') ||
                        String(viewCart?.pcLat || '') ||
                        String(viewCartData?.pcLat || '');
                    const pcLongVal =
                        String(overridePC?.longitude || '') ||
                        String(localPCLong || '') ||
                        String(browsePCObj?.long || '') ||
                        String(viewCart?.pcLong || '') ||
                        String(viewCartData?.pcLong || '');
                    // Whenever a store is changed by any user, we always update the selected store PC in ViewCart. So, using ViewCart.pc to get the correct selected store to fetch estimates.
                    payload = {
                        ...commonPayload,
                        pc: overridePC?.pc || browsePCObj?.pc || localPc || viewCart?.pc,
                        startDateAndTime: startDate ? getStartDate(isFromHTGO) : '',
                        endDateAndTime: endDate ? getEndDate(isFromHTGO) : '',
                        lat: String(projectDetails?.selectedProjectLatititude),
                        long: String(projectDetails?.selectedProjectLongitude),
                        equipment: await getEquipmentData(updatedItems),
                        salesEquipments: getSalesEquipmentData(),
                        jobSiteAddr1: projectDetails?.projectAddress1,
                        jobSiteAddr2: projectDetails?.projectAddress2,
                        jobSiteCity: projectDetails?.selectedProjectCity,
                        jobSiteState: projectDetails?.selectedProjectState,
                        jobSiteZip: formatjobsiteZip(projectDetails?.selectedProjectZip),
                        pcLat: pcLatVal?.trim(),
                        pcLong: pcLongVal?.trim(),
                        projectId: String(projectDetails?.selectedProjectJobId || '') || projectCookies?.CurrentJobSite
                    };
                } else {
                    const pcLatVal =
                        String(localPCLat || '') || String(browsePCObj?.lat || '') || String(viewCart?.pcLat || '');
                    const pcLongVal =
                        String(localPCLong || '') || String(browsePCObj?.long || '') || String(viewCart?.pcLong || '');
                    payload = {
                        ...commonPayload,
                        pc: browsePCObj?.pc || localPc || pc || 0,
                        startDateAndTime: startDate ? getStartDate(isFromHTGO) : '',
                        endDateAndTime: endDate ? getEndDate(isFromHTGO) : '',
                        lat: String(lat),
                        long: String(long),
                        equipment: await getEquipmentData(updatedItems),
                        salesEquipments: getSalesEquipmentData(),
                        jobSiteAddr1: location,
                        jobSiteAddr2,
                        jobSiteCity,
                        jobSiteState,
                        jobSiteZip: formatjobsiteZip(jobSiteZip),
                        pcLat: pcLatVal?.trim(),
                        pcLong: pcLongVal?.trim(),
                        projectId: ''
                    };
                }
            } else {
                // case when payload is not coming from viewCart
                payload = {
                    ...commonPayload,
                    pc: localLocation?.pc,
                    startDateAndTime: startDate ? getStartDate(isFromHTGO) : '',
                    endDateAndTime: endDate ? getEndDate(isFromHTGO) : '',
                    lat: String(localLocation?.lat),
                    long: String(localLocation?.long),
                    equipment: await getEquipmentData(updatedItems),
                    salesEquipments: getSalesEquipmentData(),
                    jobSiteAddr1: localLocation?.location,
                    jobSiteAddr2: localLocation?.jobSiteAddr2 || '',
                    jobSiteCity: localLocation?.jobSiteCity,
                    jobSiteState: localLocation?.jobSiteState,
                    jobSiteZip: formatjobsiteZip(localLocation?.jobSiteZip),
                    pcLat: String(localLocation?.pcLat || '')?.trim(),
                    pcLong: String(localLocation?.pcLong || '')?.trim(),
                    projectId: String(projectDetails?.selectedProjectJobId || '') || projectCookies?.CurrentJobSite
                };
            }
            const cartItems = updatedItems?.length || cart?.items?.length;
            if (cartItems) {
                if (
                    ((payload.startDateAndTime && payload.endDateAndTime) ||
                        (!payload.startDateAndTime && !payload.endDateAndTime)) &&
                    payload?.pc &&
                    payload?.pcLat &&
                    payload?.pcLong
                    // TODO 400 estimates fix with fetchRates
                    // && fetchRates(true)
                ) {
                    try {
                        dispatch({ type: 'beginLoading' });
                        cartEstimates = await getCartDetails({
                            cartDetailsQuery,
                            dispatch,
                            filterDispatch,
                            handleATPCart,
                            ...payload
                        });
                        if (cartEstimates) {
                            if (isCartUpdatedWithPrice) {
                                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CART_UPDATED_WITH_PRICE, true);
                            }
                            dispatch({ type: 'endLoading' });
                            dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
                            dispatch({
                                type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                                key: 'isEstimateOnCartOpenRequired',
                                value: false
                            });
                        }
                    } catch (err) {
                        logError(err, false, 'getEstimates', [localLocation, localPc, localPCLat, localPCLong]);
                    }
                } else {
                    dispatch({ type: 'endLoading' });
                    dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
                    dispatch({
                        type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                        key: 'isEstimateOnCartOpenRequired',
                        value: false
                    });
                }
            }

            dispatch({ type: 'endLoading' });
            dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
            dispatch({
                type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                key: 'isEstimateOnCartOpenRequired',
                value: false
            });
            return cartEstimates;
        } catch (error) {
            logError(error, false, 'getEstimates');
        }
    };

    const triggerEstimates = async () => {
        if (cart?.total_quantity > 0) {
            if (isSelectedLocationEmpty()) {
                dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
                dispatch({
                    type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                    key: 'isEstimateOnCartOpenRequired',
                    value: false
                });
            } else {
                dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
                if (isOpen) {
                    getEstimates();
                } else {
                    dispatch({
                        type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                        key: 'isEstimateOnCartOpenRequired',
                        value: true
                    });
                }
            }
        }
    };

    const api = {
        dispatch,
        getEstimates,
        getStartDate,
        getEndDate,
        triggerEstimates,
        isEstimatesWithOwnedPc,
        isSourcePCCallCriteria
    };
    return [api];
};

export default useCartEstimate;
