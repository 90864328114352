import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import {
    RESET_CLICKS,
    SET_RECOMPUTE_ITEM_AVAILABILITY,
    SET_START_CLICK
} from '../../../../../../aem-core-components/actions/constants';
import { useFilterState } from '../../../../../cap';
import { isDateDisabled } from '../../../../utils/commonUtils';
import UnavailableRental from '../../../unavailableRental/UnavailableRental';
import RangePickerOverlay from '../../../rangepicker/RangePickerOverlay';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { useAnalyticsContext } from '../../../../../../config/GoogleTagManagerEvents';
import { useCartState } from '../../../../../../contexts/cart';
import { STORAGE_CONFIG } from '../../../../../../constants/storageConfig';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../../../constants/analyticsConstants/Ecommerce';
import useMedia from '../../../../../../hooks/useMedia';
import {
    SET_END_DATE,
    SET_IS_CAP_DETAILS_UPDATING,
    SET_SELECTED_STORE_DETAILS,
    SET_START_DATE
} from '../../../../../cap/constants';
import { useCapUtils } from '../../../../../cap/hooks/useCapUtils';
import useCheckLocationEmpty from '../../../../../../hooks/useCheckLocationEmpty';
import { ModalContext } from '../../../../../../aem-core-components/context/ModalContext';
import { RENTAL_CHANGE_CONFIRMATION_MODAL } from '../../../../../../aem-core-components/context/Modal/constants';
import { LoadingIndicator } from '../../../../../../aem-core-components';
import { isValidString } from '../../../../utils/logger';
import { getDateDiffInHrs } from '../../../../../cap/utils/atputils';
import { AUTHORITY_TYPE } from '../../../../constants';
import { useCheckAuthorityType } from '../../../../../../hooks/useCheckUser';

const RangePickerOverlayWrapper = ({
    isOpen,
    minAvailableDate,
    handleCloseModal,
    showOnDrawerForTablet,
    hideBackButton,
    pickerOverlayClass,
    showAlertModal,
    isDateModalOpen
}) => {
    const [{ calendarDateInteraction, cart }, dispatch] = useCartState();
    const [{ selectedStoreDetails }, filterDispatch, { updateOldEstimates }] = useFilterState();
    const useModalState = () => useContext(ModalContext);
    const { openModal } = useModalState();
    const { sendEventsForDatePickerFormInteraction } = useAnalyticsContext();
    const [
        {
            payloadEcommerceActionAnalytics,
            sendAnalyticsDatePickerModalEvents,
            sendConfirmationModalAnalytics,
            sendConfirmationModalViewAnalytics
        }
    ] = useAnalytics();
    const mediaType = useMedia();
    const { getCartUpdatedDetails, updateBSRInStorageAndContext } = useCapUtils();
    const { fetchLocationCoordinates } = useCheckLocationEmpty();
    const { localLat, localLong } = fetchLocationCoordinates();
    const [showWarning, setShowWarning] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();
    const [isEndDateEmpty, setIsEndDateEmpty] = useState(false);
    const [availableDate, setAvailableDate] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [isStartDateEarly, setIsStartDateEarly] = useState(false);
    const [rangeState, setRangeState] = useState([
        {
            key: 'selection',
            startDate: '',
            endDate: '',
            color: '#F2F2F2'
        }
    ]);
    const authorityType = useCheckAuthorityType();
    const isP2P = [AUTHORITY_TYPE.P2P].includes(authorityType);

    useEffect(() => {
        if (minAvailableDate) {
            setSelectedStartDate('');
            setSelectedEndDate('');
            setRangeState([
                {
                    key: 'selection',
                    startDate: '',
                    endDate: '',
                    color: '#F2F2F2'
                }
            ]);
            dispatch({ type: SET_START_CLICK });
            setShowWarning(true);
            setAvailableDate(minAvailableDate);
        } else {
            setSelectedStartDate(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) || '');
            setSelectedEndDate(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE) || '');
            setRangeState([
                {
                    key: 'selection',
                    startDate: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)
                        ? new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE))
                        : '',
                    endDate: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE)
                        ? new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE))
                        : '',
                    color: '#F2F2F2'
                }
            ]);
        }
    }, [minAvailableDate]);

    useEffect(() => {
        if (!showWarning && availableDate) {
            setAvailableDate('');
        }
    }, [showWarning]);

    const handleCalendarClose = () => {
        if (isOpen && rangeState?.[0]?.startDate != '' && rangeState?.[0]?.endDate == '') {
            setIsEndDateEmpty(true);
            setShowWarning(true);
            return;
        }
        setShowWarning(false);
        setIsEndDateEmpty(false);
        handleCloseModal();
        const body = document.querySelector('body');
        body.classList.remove('calender-overflow-hidden');
    };

    const isHolidayDate = isDateDisabled();

    const customOnModalOpen = () => {
        if (!isNaN(new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getTime())) {
            if (
                new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getTime() <
                new Date().setHours(0, 0, 0, 0)
            ) {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, '');
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, '');
                filterDispatch({ type: SET_START_DATE, startDate: '' });
                filterDispatch({ type: SET_END_DATE, endDate: '' });

                return true;
            } else {
                return false;
            }
        } else return false;
    };

    const checkIfDayDisabled = date => {
        return isHolidayDate(date);
    };

    const onRentalChangeConfirmation = async (
        isAccepted,
        startDate,
        endDate,
        cartDetails,
        isCloseClicked,
        isBackClicked
    ) => {
        /* To remove the details unavailable items from the cart if user agree to proceed with updated rental details*/
        if (isAccepted) {
            filterDispatch({ type: SET_IS_CAP_DETAILS_UPDATING, isCapDetailsUpdating: true });
            //commenting it as we will have t display unavailable items in cart
            //await removeUnavailableItemsFromCart(cartDetails);
            /* To update the date and store detials in context*/
            saveDateInContext(startDate, endDate);
            filterDispatch({ type: SET_IS_CAP_DETAILS_UPDATING, isCapDetailsUpdating: false });
        }
        sendConfirmationModalAnalytics(isAccepted, isCloseClicked, isBackClicked, cartDetails);
    };
    const updateDateContext = async (startDate, endDate) => {
        /* To show the alert modal when rental details are chnaged and items in the cart are there*/
        const diffInHours = getDateDiffInHrs(startDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
        if (startDate != '' && new Date(startDate).getTime() == new Date(endDate).getTime()) {
            setShowWarning(true);
            return false;
        } else if (endDate == '' || startDate == '') {
            setIsEndDateEmpty(true);
            setShowWarning(true);
            return false;
        } else if (diffInHours <= 48 && isP2P) {
            setIsStartDateEarly(true);
            setShowWarning(true);
            return false;
        } else {
            if (
                (showAlertModal &&
                    isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0) ||
                cart?.total_quantity > 0
            ) {
                setShowLoader(true);
                const storeDetailData = JSON.parse(
                    localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.LOCALSELECTEDSTOREDETAILS) || '{}'
                );
                /* Fetch the changed details from the compare estimates graphQL*/
                const cartDetails = await getCartUpdatedDetails({
                    filterStartDate: startDate,
                    filterEndDate: endDate,
                    selectedPickupStore: isDateModalOpen ? storeDetailData : selectedStoreDetails
                });
                setShowLoader(false);
                if (cartDetails?.length) {
                    openModal(RENTAL_CHANGE_CONFIRMATION_MODAL, {
                        isOpen: true,
                        productList: cartDetails,
                        handleConfirmation: (isAccepted, isCloseClicked, isBackClicked) =>
                            onRentalChangeConfirmation(
                                isAccepted,
                                startDate,
                                endDate,
                                cartDetails,
                                isCloseClicked,
                                isBackClicked
                            )
                    });
                    sendConfirmationModalViewAnalytics(cartDetails);
                    return;
                }
            }
            saveDateInContext(startDate, endDate);
            return true;
        }
    };

    const saveDateInContext = async (startDate, endDate) => {
        /* Update the date and store detials in the context when user click on done button*/
        filterDispatch({
            type: SET_START_DATE,
            startDate: isNaN(new Date(startDate).getDate()) ? '' : startDate.toString()
        });
        filterDispatch({ type: SET_END_DATE, endDate: isNaN(new Date(endDate).getDate()) ? '' : endDate.toString() });
        // removing bsr if dates are greater than 24 hrs
        const dateDiff = getDateDiffInHrs(startDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
        if (dateDiff > 24) {
            updateBSRInStorageAndContext();
        }
        /* Update the selected store details for change store and change date CTA when done is clicked  */
        const storeDetailData = JSON.parse(
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.LOCALSELECTEDSTOREDETAILS) || '{}'
        );
        if (isDateModalOpen) {
            filterDispatch({
                type: SET_SELECTED_STORE_DETAILS,
                selectedStoreDetails: storeDetailData,
                localLat,
                localLong
            });
            localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.LOCALSELECTEDSTOREDETAILS);
        }

        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, startDate);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, endDate);
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS);
        dispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
    };

    const handleResetClicked = () => {
        dispatch({ type: RESET_CLICKS });
    };

    const handleDateEventsInteraction = (date, fieldName) => {
        sendEventsForDatePickerFormInteraction(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_INTERACTION,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
            fieldName,
            date,
            payloadEcommerceActionAnalytics(false)
        );
    };

    const sendDatePickerModalEvents = (eventName, startDate, endDate) => {
        sendAnalyticsDatePickerModalEvents(eventName, startDate, endDate);
    };

    return (
        <>
            <RangePickerOverlay
                isOpen={isOpen}
                showWarning={showWarning}
                setShowWarning={setShowWarning}
                setIsEndDateEmpty={setIsEndDateEmpty}
                rangeState={rangeState}
                setRangeState={setRangeState}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
                handleToggle={handleCalendarClose}
                customOnModalOpen={customOnModalOpen}
                checkIfDayDisabled={checkIfDayDisabled}
                handleSelectedDatesOnDone={updateDateContext}
                onResetClick={handleResetClicked}
                handleDateEventsInteraction={handleDateEventsInteraction}
                sendDatePickerModalEvents={sendDatePickerModalEvents}
                calendarDateInteraction={calendarDateInteraction}
                mediaType={mediaType}
                minDate={minAvailableDate}
                showOnDrawerForTablet={showOnDrawerForTablet}
                hideBackButton={hideBackButton}
                pickerOverlayClass={pickerOverlayClass}
                setIsStartDateEarly={setIsStartDateEarly}
                isStartDateEarly={isStartDateEarly}
                renderWarningComponent={
                    <UnavailableRental
                        showWarning={showWarning}
                        isOpen={isOpen}
                        handleToggle={handleCalendarClose}
                        isEndDateEmpty={isEndDateEmpty}
                        minAvailableDate={availableDate}
                        isStartDateEarly={isStartDateEarly}
                    />
                }
            />
            {showLoader && (
                <div className="cap-date-overlay">
                    <LoadingIndicator global />
                </div>
            )}
        </>
    );
};

export default React.memo(RangePickerOverlayWrapper);

RangePickerOverlayWrapper.defaultProps = {
    isOpen: false,
    handleCloseModal: () => {},
    showOnDrawerForTablet: false,
    hideBackButton: false,
    pickerOverlayClass: '',
    showAlertModal: false,
    isDateModalOpen: false
};

RangePickerOverlayWrapper.propTypes = {
    isOpen: bool,
    minAvailableDate: oneOfType([string, instanceOf(Date)]),
    handleCloseModal: func,
    showOnDrawerForTablet: bool,
    hideBackButton: bool,
    pickerOverlayClass: string,
    showAlertModal: bool,
    isDateModalOpen: bool
};
